export default {
  pending: 'Așteptare aprobare',
  approved: 'Aprobat',
  rejected: 'Respins',
  cancelled: 'Anulat',
  generated: 'Generat',
  disabled: 'Dezactivat',
  approvalready: 'Așteptare aprobare în urma avizării',
  avizo: 'În proces de avizare',
  editing: 'Editare în urma avizării negative',
  asteptare_editare_in_urma_anularii_de_catre_un_administrator:
    'Așteptare editare în urma anulării de către un administrator',
}
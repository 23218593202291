<template>
  <div class="statistics_box" @click="$emit('click', $event)">
    <div class="l_box">
      <div class="ico">
        <slot name="icon"></slot>
      </div>
    </div>
    <div class="r_box">
      <div class="count">
        <slot></slot>
      </div>
      <div class="Label">
        <slot name="label-right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style src="../assets/StatisticsBox.css"></style>
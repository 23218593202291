<template>
  <Teleport to="#app">
    <transition appear name="ModalTranition">
      <div v-if="open" class="Popup_Parent" :id="modalId">
        <div class="Popup-bg" :class="{disabled: disabledBg}" @click="close()"></div>
        <div class="Popup_Inner" :style="styles">
          <slot></slot>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script>
const symbolId = Symbol();

export default {
  props: {
    value: {
      type: [Boolean, Symbol],
      default: symbolId,
    },
    extendedMode: {
      default: "",
    },
    disabledBg: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    open() {
      return this.value === symbolId
        ? !this.isObjEmpty(this.$slots)
        : this.value;
    },
    styles() {
      const map = {
        true: "max-width: 217rem; max-height: 64em;",
        1: "max-width: 131rem; max-height: 64em;",
        2: "max-width: 55rem; max-height: 25em;",
        3: "max-width: 100rem; max-height: 35em;",
      };

      return map[String(this.extendedMode)] || "";
    },
  },
  watch: {
    open: {
      handler(x) {
        this.setListener(!! x)
      },
      immediate: true
    }
  },
  methods: {
    close(event) {
      if(window._PreventEscCloseForPopup || !event && this.disabledBg) return

      const close = () => this.$emit("input", false);
      
      if(event) {
        if(event.key === "Escape") {
          close()
        }
      } else close()
    },
    setListener(pos) {
      document[`${pos ? 'add' : 'remove'}EventListener`]('keyup', this.close)
    }
  },
  data() {
    return {
      modalId: `ID${this.makeid(9)}`
    };
  },
  beforeDestroy() {
    document.getElementById(this.modalId)?.remove();
    this.setListener()
  },
};
</script>

<style scoped>
.Popup_Parent {
  z-index: 2000 !important;
}
</style>
<style>
.ModalTranition-enter-active,
.ModalTranition-leave-active {
  transition: opacity 0.35s !important;
}
.ModalTranition-enter,
.ModalTranition-leave-to {
  opacity: 0 !important;
}
</style>
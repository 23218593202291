export default {
  methods: {
    readAsDataUrl(file) {
      if (typeof FileReader === 'function') {
        return new Promise(resolve => {
          const reader = new FileReader();
          reader.addEventListener('load', (event) => {
            resolve(event.target.result)
          });
          reader.readAsDataURL(file);
        })
      } else {
        this.$toastr.e('Sorry, FileReader API not supported.');
        return ''
      }
    },
    dataURLtoFile(dataurl, filename = 'File') {
      let   arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
          
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
    },
    base64ToArrayBuffer(base64) {
      // const binary_string = window.atob(base64);
      const len = base64.length;
      let bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = base64.charCodeAt(i);
      }
      return bytes.buffer;
    },
    b64ToBlob(b64Data, contentType='', sliceSize=512) {
      if(!this.isString(b64Data)) return
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
    
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
    
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
    
      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },
    imageEditor(srcBase64, options) {
      if(!srcBase64 || !options?.type) {
        return
      }
      const canvas = document.createElement('canvas');
      let ctx = canvas.getContext("2d");
      let image = new Image();

      return new Promise(resolve => {
        image.addEventListener('load', () => {
          canvas.width = options.degrees % 180 === 0 ? image.width : image.height;
          canvas.height = options.degrees % 180 === 0 ? image.height : image.width;
          if(options.type == 'rotate' && options.degrees) {
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(options.degrees * Math.PI / 180);
          } else if(options.type == 'flip' && options.directions) {
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.scale(options.directions.scaleX || 1, options.directions.scaleY || 1);
          }
          ctx.drawImage(image, image.width / -2, image.height / -2);

          resolve(canvas.toDataURL())
        })
        image.src = srcBase64;
      })
    }
  }
}
<template>
  <div style="padding-bottom: 2rem;">
    <Navbar :showSidebarBtn="!sidebarHidden" :hideMobileSidebar="hideMobileSidebar" @viewMobileSidebar="x => showMobileSidebar = !!x" />
    <router-view v-if="sidebarHidden && roleValide"></router-view>
    <div v-else class="container" style="margin-bottom: 1rem;">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
          <div
            id="reduceLateralWidth"
            class="dashboard border-radius"
            style="margin-top: -9.75rem !important;display: grid;overflow: auto;"
          >
            <div
              v-if="roleValide"
              class="control grid-background height mobile"
              style="margin: 0 !important;"
            >
              <transition name="user_inner_dropdown">
                <div
                  class="col1 sidebar_main"
                  :class="{ isMinimized: isMinimized }"
                  v-if="!sidebarHidden && !isPublicPage"
                  v-show="showMobileSidebar"
                >
                  <Sidebar
                    @minimizeSidebar="(val) => (isMinimized = val)"
                    @refresh="refreshPage"
                    @closeMobileSidebar="hideMobileSidebar++"
                  />
                </div>
              </transition>
              <div class="col2">
                <router-view :key="currViewKey" @refresh="refreshPage"></router-view>
              </div>
            </div>
            <template v-else>
              <div>
                <div
                  style="height: 9.75rem;border-bottom: 1px solid #c3c3c3;display: flex;align-items: center;justify-content: center;"
                >
                  <h1 class="adaptiveFont" align="center" style="margin: 0;">
                    Rolul selectat de dvs. nu mai este valabil
                  </h1>
                </div>
                <h3
                  class="adaptiveFont"
                  align="center"
                  style="margin-top: 1rem;"
                >
                  Posibil instituția sau departamentul în care vă aflați nu mai
                  există.
                </h3>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div
      id="EcosistemDigitalCopyright"
      style="text-align: center;line-height: 1;color: #999;font-size: 1.5rem;"
    >
      Ecosistem digital © {{ currYear }}
    </div>
  </div>
</template>

<script>
import updateRoleStatus from '@/mixins/updateUserRoleStatus.js'
import userAvatarMixin from '@/mixins/userAvatar.js'
import { mapGetters, mapMutations } from "vuex"
import Navbar from "@/components/NewNavbar"
import Sidebar from "@/components/NewSidebar"

export default {
  $Title: "ConectX",
  mixins: [updateRoleStatus, userAvatarMixin],
  components: {
    Sidebar,
    Navbar,
  },
  data() {
    return {
      currViewKey: 0,
      hideMobileSidebar: 0,
      sidebarHidden: false,
      roleValide: true,
      isMinimized: false,
      showMobileSidebar: false,
      refreshing: false,
      currYear: new Date().getFullYear()
    }
  },
  computed: {
    ...mapGetters(["userRole"]),
    isPublicPage() {
      return !this.$route.meta?.needAuth && !this.userRole
    },
  },
  watch: {
    $route(to) {
      if (to.path === "/") {
        this.sidebarHidden = true
      } else {
        this.sidebarHidden = false
      }
    },
    userRole(val) {
      this.updateRoleStatus()
      this.checkRole(val)
    },
  },
  methods: {
    ...mapMutations({ setGlobalRole: "setUserRole" }),
    checkRole(val) {
      const findRole = val || window.$getRoleAll()
      const setValid = x => this.roleValid = !!x

      if (findRole?.role) {
        const x = String(findRole.role)
          .trim()
          .toLowerCase()
        
        if (x == 2) {
          if (!Number.isInteger(findRole.institutionId)) {
            setValid(false)
          } else {
            setValid(true)
          }
          return
        } else if (x == 3) {
          if (
            !(findRole.departmentId || findRole.departmentId === 0) ||
            !(findRole.institutionId || findRole.institutionId === 0)
          ) {
            setValid(false)
          } else {
            setValid(true)
          }
          return
        } else if (x === 1) {
          setValid(true)
        }
      } else {
        setValid(false)
      }
    },
    refreshPage() {
      if(!this.refreshing) {
        this.refreshing = true
        this.$nextTick(() => {
          this.currViewKey++
          this.refreshing = false
        })
      }
    }
  },
  async created() {
    this.updateRoleStatus()
    this.checkRole()
    if (!localStorage.getItem("currentUserRole")) {
      this.setGlobalRole(window.$getRole())
      if (window.$getRole())
        localStorage.setItem("currentUserRole", window.$getRole()?.id)
    }
    if (this.$route.path == "/") {
      this.sidebarHidden = true
    } else {
      this.sidebarHidden = false
    }
    
    this.$nextTick(() => {
      if(!this.isPublicPage) {
        this.initUserAvatar()
      }
    })
  },
}
</script>

<style>
.sidebar-enter-active,
.sidebar-leave-active {
  transition: opacity 0.35s, transform 200ms;
}
.sidebar-enter,
.sidebar-leave-to {
  opacity: 0;
}
@media screen and (max-width: 900px) {
  h1.adaptiveFont {
    font-size: 4vw;
  }
  h3.adaptiveFont {
    font-size: 3vw;
  }
}
</style>

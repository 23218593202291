import Vue from 'vue'
import titleMixin from './mixins/titleMixin'
import Permisions from './permisions.json'
import basePermReader from './mixins/permisionReaderMinimized.js'
import App from './App.vue'
import router from './router'
import mainMixin from './mixins/mainMixin.js'
import realTime from './mixins/realTime.js'
import VueElementLoading from 'vue-element-loading'
import AsyncComputed from 'vue-async-computed'
import { Plugin as VueFragment } from 'vue-fragment'
import VueTelInput from 'vue-tel-input'
import VueJWT from 'vuejs-jwt'
import VTooltip from 'v-tooltip'
import infiniteScroll from 'vue-infinite-scroll'
import VueUrlStateSync from 'vue-url-state-sync'
import store from './store'
import VueToastr from './modules/vue-toastr'
import vSelect from './modules/vue-select'
import VModal from 'vue-js-modal'
import Teleport from 'vue2-teleport'
import ClickOutside from 'vue-click-outside'
import ModalParent from '@/components/ModalParent'
import VueSelectInfiniteScroll from '@/components/InfiniteScroll'
import NumberInput from '@/components/NumberInput'
import Editor from './components/Editor'
import Tabs from 'vue-tabs-component'
import StatisticsBox from './components/StatisticsBox'
import VueFileAgent from 'vue-file-agent'
import ZoomOnHover from 'vue-zoom-on-hover'
import acquisitionType from './common/acquisition-type.js'
// import 'vue-tel-input/dist/vue-tel-input.css'
import './modules/vue-select/dist/vue-select.css'
import 'vue-file-agent/dist/vue-file-agent.css'

const PermisionsKeys = Object.keys(Permisions)
if (PermisionsKeys && PermisionsKeys.length) {
  window.$P = Permisions
}
Vue.config.productionTip = false

Vue.mixin(titleMixin)
Vue.use(infiniteScroll)
Vue.use(AsyncComputed)
Vue.use(VueFragment)
Vue.use(VueTelInput, {
  onlyCountries: ['ro'],
  defaultCountry: 'ro',
  dropdownOptions: {
    disabled: true,
    showDialCodeInSelection: false,
  },
  'dropdownOptions.disabled': true,
  inputOptions: { placeholder: ' ' },
})
Vue.use(VueJWT, {
  keyName: 'user_token',
  ignoreExpiration: false,
})
Vue.use(VueUrlStateSync)
Vue.use(VTooltip, {
  defaultBoundariesElement: 'window',
  defaultOffset: 4,
})
Vue.use(VModal, { dialog: true })
Vue.use(VueToastr, {
  defaultTimeout: 3000,
  defaultCloseOnHover: true,
  defaultProgressBar: false,
  defaultClassNames: ['animated', 'zoomIn'],
  
  // Custom Default Options
  defaultWarningTitle: 'Atenție!'
})
Vue.use(ZoomOnHover)
Vue.use(Tabs)
Vue.use(VueFileAgent)

Vue.mixin(mainMixin)
Vue.mixin(acquisitionType)
Vue.mixin(realTime)

Vue.directive('click-outside', ClickOutside)

let handleOutsideClick
Vue.directive('closable', {
  bind(el, binding, vnode) {
    handleOutsideClick = e => {
      e.stopPropagation()
      const { handler, exclude } = binding.value
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName]
          clickedOnExcludedEl = excludedEl.contains(e.target)
        }
      })
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]()
      }
    }
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },

  unbind() {
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  },
})
Vue.directive('maxchars', {
  update(el, binding, vnode) {
    var event = new Event('input', { bubbles: true })
    let maxChars = binding.value
    if (el.value.length > maxChars) {
      el.value = el.value.substr(0, maxChars)
      el.dispatchEvent(event)
    }
  },
})
Vue.directive('number', {
  update(el, binding) {
    const event = new Event('input', { bubbles: true })
    function toLocaleNumber(str, notFloat) {
      if (!notFloat) {
        const a = String(str)
          .replace('.', ',')
          .replace(/[^0-9,]/g, '')
          .split(',')
        if (!a[0]) {
          return ''
        }
        return a.slice(0, 2).join(',') + a.slice(2).join('')
      } else {
        return str.replace(/[^0-9]/g, '')
      }
    }
    el.value = toLocaleNumber(el.value, !binding.modifiers.float)
    el.dispatchEvent(event)
  },
})
Vue.directive('focus', {
  bind(el) {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        el.focus()
      })
    })
  },
})

Vue.component('v-loading', VueElementLoading)
Vue.component('v-select', vSelect)
Vue.component('editor', Editor)
Vue.component('Teleport', Teleport)
Vue.component('clasic-modal', ModalParent)
Vue.component('v-select-infinite', VueSelectInfiniteScroll)
Vue.component('inp-number', NumberInput)
Vue.component('statistics-box', StatisticsBox)

window.$permReader = basePermReader
window.$jwt = Vue.$jwt

window.$getRole = function() {
  let roles = window.$jwt.decode(window.$jwt.getToken())
  if (roles?.userRoles) roles = roles.userRoles
  else return

  const storage = parseInt(localStorage.getItem('currentUserRole'))

  if (roles.length && Number.isInteger(storage)) {
    const find = roles.find(e => e.id === storage)
    if (find) {
      return find
    }
  } else if (roles.length) {
    return roles.find(e => e.userRoleStatus == 'active')
  }
  return false
}
window.$getRoleAll = function() {
  let roles = window.$jwt.decode(window.$jwt.getToken())
  if (roles && roles.userRoles) roles = roles.userRoles
  else return
  if (!roles.length) return {}
  const role = window.$getRole()
  const find = roles.find(e => e?.id === role?.id)
  if (find) return find
  return roles[0]
}
window.$getRoleToSend = function() {
  let roles = window.$jwt.decode(window.$jwt.getToken())
  if (roles && roles.userRoles) roles = roles.userRoles
  else return
  if (!roles.length) return {}
  const role = window.$getRole()
  const find = roles.find(e => e?.id === role?.id)

  if (find) {
    return {
      id: find.id,
      institutionId: find.institutionId,
      departmentId: find.departmentId,
      role: find.role,
    }
  }
  return {
    id: roles[0].id,
    institutionId: roles[0].institutionId,
    departmentId: roles[0].departmentId,
    role: roles[0].role,
  }
}
const numeral = require('numeral')
const moment = require('moment')
numeral.register('locale', 'ro', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  // ordinal : function (number) {
  //     return number === 1 ? 'er' : 'ème';
  // },
  currency: {
    symbol: 'RON',
  },
})
numeral.locale('ro')
numeral.defaultFormat('0,0.00')
window['numeral'] = numeral

window.$getAllDateAndTime = function() {
  return moment().format('DD.MM.YYYY HH:mm:ss')
}
window.$getDateAndTime = function() {
  return moment().format('DD.MM.YYYY HH:mm')
}
window.$getDate = function() {
  return moment().format('DD.MM.YYYY')
}
window.$getTime = function() {
  return moment().format('HH:mm')
}
window.$toDateAndTime = function(string, showSeconds) {
  return moment(String(string).trim()).format(
    'DD.MM.YYYY HH:mm' + (showSeconds ? ':ss' : '')
  )
}
window.$toDate = function(string) {
  return string ? moment(String(string).trim()).format('DD.MM.YYYY') : null
}
window.$toPriceFormat = function(value) {
  value = +value

  if (!['number', 'string'].includes(typeof value) || isNaN(value)) return null

  return numeral(value).format()
}
window['apiTimeout'] = (
  timeout = 400,
  fixedTimeout = false,
  lowPrecision = false,
  useOnlyLastApiCall = false
) => {
  const getCurrentTime = () => new Date().getTime()

  let interval = null
  let lastSent = 0
  let sentCount = 0

  return callback => {
    sentCount++
    (count => {
      if(useOnlyLastApiCall && count != sentCount) {
        return
      }
      clearInterval(interval)
      const x = getCurrentTime() - lastSent
      if (lowPrecision && x < timeout) {
        return
      }
      interval = setTimeout(
        () => {
          lastSent = getCurrentTime()
          callback()
        },
        fixedTimeout ? timeout : x > timeout ? 0 : timeout - x
      )
    })(sentCount)
  }
}
window['validator'] = function(exceptions = [], ignoreObjDeep = []) {
  if (!this) return false
  if (!Array.isArray(exceptions)) exceptions = []
  if (!Array.isArray(ignoreObjDeep)) ignoreObjDeep = []

  const checkArray = array => !!array?.filter(e => e).length
  const textFromHtml = text => {
    const x = document.createElement('div')
    x.innerHTML = text
    return x.innerText
  }

  if (Object.prototype.toString.call(this) === '[object String]') {
    return !!textFromHtml(this).trim()
  }

  if (Array.isArray(this) && !checkArray(this)) {
    return false
  }

  for (const e of Object.entries(this).filter(
    e => !exceptions.some(i => e[0] == i)
  )) {
    if (!e[1]) return false
    if (Array.isArray(e[1])) {
      if (!checkArray(e[1])) return false
    } else {
      if (
        typeof e[1] == 'object' &&
        !ignoreObjDeep.includes(e[0]) &&
        !validator(e[1])
      )
        return false
    }
  }

  return true
}

console.log(window.$jwt.decode(window.$jwt.getToken()))

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

export default {
  data() {
    return {
      PERMISIONS: {
        profile: true,
        currency: false,
        register: false,
        projects: false,
        financing: false,
        contract: false,
        proposals: false,
        productsCreate: false,
        paap: false,
        needReference: false,
        needs: false,
        needsModify: false,
        products: false,
        confirmProducts: false,
        cpvCategories: false,
        measurmentUnits: false,
        budgetSources: false,
        procedureTypes: false,
        institutions: false,
        institution: false,
        departments: false,
        users: false,
        rnObiective: false,
        achizitii: false,
        bugetare: false,
        complete: false,
        institutionObjectives: true,
        annualInstitutionObjectives: true,
        assignments: false,
        institutionProvider: false,
      },
    };
  },
  computed: {
    adminPagesAllowed() {
      const adminKeys = [
        "cpvCategories",
        "measurmentUnits",
        "budgetSources",
        "procedureTypes",
        "institutions",
        "departments",
        "rnObiective",
      ];
      for (const key of adminKeys) {
        if (this.PERMISIONS[key] === true) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    initAllPermisions() {
      const baseAction = "get";
      const parmMap = {
        projects: "projects",
        financing: "financing",
        contract: "contract",
        currency: "currency",
        registru_praguri: "register",
        paap: "paap",
        "referat-necesitate": "needReference",
        needs: "needs",
        products: "products",
        "cpv-categories": "cpvCategories",
        "measurement-units": "measurmentUnits",
        "budget-source": "budgetSources",
        "procedure-types": "procedureTypes",
        institutions: "institutions",
        institution: "institution",
        departments: "departments",
        usersManagement: "users",
        referate_obiective: "rnObiective",
        achizitii: "achizitii",
        bugetare: "bugetare",
        "institution-objectives": "institutionObjectives",
        "annual-institution-objectives": "annualInstitutionObjectives",
        assignments: "assignments",
        "institution-provider": "institutionProvider",
      };
      Object.entries(parmMap).forEach((e) => {
        this.initPermision(`${e[0]}.${baseAction}`, e[1]);
      });

      this.initPermision(`products.post`, "productsCreate");
      this.initPermision(`needs.patch`, "needsModify", ["pending"]);
      this.initPermision(`products.confirm`, "confirmProducts");
      this.initPermision(`referat-necesitate.complete`, "complete");
      this.initPermision(`institution.view_details`, "institution");
    },
  },
};

<template>
  <input v-model="val" v-maxchars="maxChars" type="text" class="vInput" :class="disabled ? 'form-disabled' : ''" :disabled="disabled">
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    min: {
      type: [String, Number],
      default: ''
    },
    max: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isPrice: {
      type: Boolean,
      default: true
    },
    maxChars: {
      type: Number,
      default: 999
    },
    isFloat: {
      type: Boolean,
      default: true
    },
    floatLength: {
      type: Number,
      default: 4
    },
    stringMode: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    val(y) {
      if(this.isPrice) {
        let x = this.getLocaleNumber(y)
        if(x !== '' && this.min !== '' && x < this.min) y = this.min
        else if (this.max !== '' && x > this.max) y = this.max
        if(x > 1000000000) y = 1000000000
        this.val = this.toLocaleNumber(y, !this.isFloat, this.floatLength)
      } else {
        this.val = this.parseNumber(this.val)
      }

      this.emit()
    },
    value() {
      this.syncVal()
    }
  },
  methods: {
    syncVal() {
      if(this.stringMode) return
      this.val = this.getValue()
    },
    getValue() {
      if(!this.isPrice) {
        return this.value
      }

      let x = this.value

      if(isNaN(x)) return ''
      if(this.isString(x)) {
        x = parseFloat(x)
        if(!x) {
          return ''
        }
      }

      return this.toLocaleNumber(x)
    },
    emit() {
      if(this.disabled) return

      if(this.isPrice) {
        const x = this.getLocaleNumber(this.val)
        const y = isNaN(x) ? '' : x
        
        this.$emit('input', this.stringMode ? String(y) : y)
      } else {
        this.$emit('input', this.stringMode ? String(this.val) : this.val)
      }
    }
  },
  data() {
    return {
      val: this.getValue()
    }
  },
  created() {
    this.emit()
  }
}
</script>
export default {
  data() {
    return {
      acquisitionTitle: {
        cmd1: "COMANDĂ FERMĂ 1",
        cmd1_a2: "COMANDĂ FERMĂ 1",
        cmd31: "COMANDĂ FERMĂ ART.31",
        crt1: "CONTRACT 1",
        crt1_a2: "CONTRACT 1",
        crt31: "CONTRACT ART.31",
        cmd3: "COMANDĂ FERMĂ 3",
        cmd3_a2: "COMANDĂ FERMĂ 3",
        cmd4: "COMANDĂ FERMĂ 4",
        cmd2: "COMANDĂ FERMĂ 2",
        cmd2_a2: "COMANDĂ FERMĂ 2",
        crt3: "CONTRACT 3",
        crt2: "CONTRACT 2",
        crt3_a2: "CONTRACT 3",
        crt2_a2: "CONTRACT 2",
      },

      acquisitionNumberTitle: {
        cmd1: "Număr achiziție S.E.A.P",
        cmd1_a2: "Număr achiziție S.E.A.P",
        cmd3: "Număr proces verbal",
        cmd3_a2: "Număr proces verbal",
        cmd31: "Număr achiziție S.E.A.P/Oferta",
        crt1: "Număr achiziție S.E.A.P",
        crt1_a2: "Număr achiziție S.E.A.P",
        crt31: "Număr achiziție S.E.A.P/Oferta",
        cmd4: "Număr document fiscal",
        cmd2: "Număr proces verbal",
        cmd2_a2: "Număr proces verbal",
        crt3: "Număr proces verbal",
        crt3_a2: "Număr proces verbal",
        crt2: "Număr proces verbal",
        crt2_a2: "Număr proces verbal",
      },

      acquisitionDateTitle: {
        cmd1: "Data achiziție",
        cmd1_a2: "Data achiziție",
        cmd3: "Data proces verbal",
        cmd3_a2: "Data proces verbal",
        cmd31: "Data achiziție/Ofertei",
        crt1: "Data achiziție",
        crt1_a2: "Data achiziție",
        crt31: "Data achiziție/Ofertei",
        cmd4: "Data documentului fiscal",
        cmd2: "Data proces verbal",
        cmd2_a2: "Data proces verbal",
        crt3: "Data proces verbal",
        crt2: "Data proces verbal",
        crt3_a2: "Data proces verbal",
        crt2_a2: "Data proces verbal",

      },
    };
  },
};

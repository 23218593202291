import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userRoleObj: {},
    Notifications: [],
    windows: [],
    userAvatar: '',
    referenceIdForCreate: false,
    referenceIdForPreview: false,
    currentReferenceSidebar: false,
    procedureTypes: {},
    Data: {
      productData: [],
      proposalsTableData: [],
      needsTableData: [],
      departmentData: [],
      paapCentralization: []
    },
    exchange: null
  },
  getters: {
    getExchange(state) {
      return state.exchange
    },
    getProcedureTypes(state) {
      return state.procedureTypes
    },
    notifications(state) {
      return state.Notifications
    },
    userRole(state) {
      return state.userRoleObj
    },
    USER_AVATAR(state) {
      return state.userAvatar
    },
    Window(state) {
      return state.windows
    },
    getProductData(state) {
      return state.Data.productData
    },
    getProposalsTableData(state) {
      return state.Data.proposalsTableData
    },
    getNeedsTableData(state) {
      return state.Data.needsTableData
    },
    getDepartmentData(state) {
      return state.Data.departmentData
    },
    getReferenceIdForCreate(state) {
      return state.referenceIdForCreate
    },
    getReferenceIdForPreview(state) {
      return state.referenceIdForPreview
    },
    getCRS(state) {
      return state.currentReferenceSidebar
    },
    getPaapCentralizationTableData(state) {
      return state.Data.paapCentralization
    },
  },
  mutations: {
    setExchange(state, exchange) {
      state.exchange = exchange
    },
    setUserRole(state, role) {
      state.userRoleObj = role
    },
    setProcedureTypes(state, item) {
      state.procedureTypes = item
    },
    setNotifications(state, data) {
      state.Notifications = data
    },
    setUserAvatar(state, avatar) {
      state.userAvatar = avatar
    },
    addWindow(state, item) {
      state.windows.push(item)
    },
    setWindow(state, item) {
      state.windows = item
    },
    delWindow(state, item) {
      state.windows.pop()
    },
    setProductData(state, to) {
      state.Data.productData = to
    },
    setProposalsTableData(state, to) {
      state.Data.proposalsTableData = to
    },
    setNeedsTableData(state, to) {
      state.Data.needsTableData = to
    },
    setDepartmentData(state, to) {
      state.Data.departmentData = to
    },
    setReferenceIdForCreate(state, to) {
      state.referenceIdForCreate = to
    },
    setReferenceIdForPreview(state, to) {
      state.referenceIdForPreview = to
    },
    setCurrentReferenceSidebar(state, val) {
      state.currentReferenceSidebar = val
    },
    setPaapCentralizationTableData(state, to) {
      state.Data.paapCentralization = to
    },
  },
  actions: {
  },
  modules: {
  }
})

<template>
  <div>
    <clasic-modal v-model="modalOpen" extendedMode="2">
      <div class="UserRoleChooser">
        <div>
          <div>
            <span>Alege rol</span>
          </div>
          <div @click="setModal()">
            <i class="fas fa-times"></i>
          </div>
        </div>
        <div>
          <div v-if="!modalData || !modalData.length">
            <h4 class="prettyH4" style="color: #888">
              <i class="fas fa-exclamation-triangle"></i>
              Nu sunt găsite roluri
            </h4>
          </div>
          <div v-else>
            <div
              v-for="(rol, index) in modalData"
              :key="rol.id"
              @click="changeRole(rol, true)"
              :class="{
                itm_active: rol.isActive,
                RoleIsDisabled: rol.userRoleStatus != 'active',
              }"
              class="user_inner_dropdown_inner_item"
              :style="index === rolesLastIndex ? 'margin-bottom:unset;' : ''"
            >
              <div class="user_inner_avatar">
                <span v-if="photoCache[rol.institutionId] === true">
                  <i class="fas fa-sync fa-spin"></i>
                </span>
                <img
                  v-else
                  :src="photoCache[rol.institutionId] || Placeholder"
                  alt="Avatar"
                />
              </div>
              <div class="user_inner_info">
                <div class="user_inner_info_email">
                  {{ rol.departmentName }}
                </div>
                <div
                  class="user_inner_info_role"
                  :style="rol.departmentName ? '' : 'font-size: 2rem;'"
                >
                  {{
                    rol._role +
                      (rol.userRoleStatus != "active"
                        ? ` (${
                            {
                              disabled: "Dezactivat",
                              unconfirmed: "Neconfirmat",
                            }[rol.userRoleStatus]
                          })`
                        : "")
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </clasic-modal>
    <div class="pre-header-container">
      <div class="header-container move-on-bottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-3 col-xs-9">
              <div class="row">
                <div class="col-xs-6 col-sm-10 col-md-12 logo-dash">
                  <router-link to="/">
                    <img src="@/assets/media/logo.svg" alt="Brand logo"
                  /></router-link>
                </div>
              </div>
            </div>
            <template v-if="!isPublicPage">
              <div class="col-lg-7 col-md-7 col-sm-4 col-4">
                <transition name="user_inner_dropdown">
                  <ul
                    v-if="!isPublicPage"
                    v-show="mobileMenuStatus === 1"
                    class="nav navbar-nav digitax_navbar responsive_collapse"
                    style="padding-right: 1rem"
                  >
                    <li class="active">
                      <router-link to="/" class="need-exact"
                        ><i class="fas fa-home"></i
                      ></router-link>
                    </li>
                    <li>
                      <router-link to="/profil">Profil</router-link>
                    </li>

                    <li
                      v-for="item in navbarElements"
                      v-if="item.show.includes(getUserRole())"
                    >
                      <router-link v-if="!item.mainKey" :to="item.mainLink">{{
                        item.name
                      }}</router-link>

                      <router-link
                        v-else-if="item.mainKey && PERMISIONS[item.mainKey]"
                        :to="item.mainLink"
                        >{{ item.name }}</router-link
                      >
                      <router-link v-else :to="item.secondLink">{{
                        item.name
                      }}</router-link>
                    </li>
                  </ul>
                </transition>
              </div>

              <div class="col-lg-3 col-md-3 col-sm-5 col-xs-3">
                <div class="row">
                  <div
                    style="display: flex; align-items: center; justify-content: center;"
                  >
                    <div
                      style="color: white; width: 0; transform: translateX(-5rem)"
                    >
                      <notification
                        @click="() => (userInnerDropdown = false)"
                      />
                    </div>

                    <div style="width: 100%;">
                      <div
                        class="
                        col-lg-4 col-md-4 col-sm-4 col-xs-12
                        mobile-align
                        pr-5
                        text-center
                      "
                      >
                        <div class="dropdown" id="account-dropdown">
                          <a
                            id="dropdownMenu1"
                            href="#"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="true"
                            @click.prevent="
                              userInnerDropdown = !userInnerDropdown
                            "
                            v-click-outside="onClose"
                          >
                            <div class="image_shadow">
                              <img
                                v-show="USER_AVATAR"
                                :src="USER_AVATAR"
                                class="mobile-profile-width"
                                alt="Avatar"
                              />
                              <i
                                v-show="!USER_AVATAR"
                                style="color: white"
                                class="fas fa-sync fa-spin"
                              ></i>
                            </div>
                          </a>
                        </div>
                      </div>

                      <div
                        class="
                        col-lg-8 col-md-8 col-sm-8
                        hidden-xs
                        large-screen-padding
                        pl-0
                        profile_label
                      "
                      >
                        <div>
                          <label class="user-label white">
                            {{ jwt.last_name + " " + jwt.first_name }}</label
                          >
                        </div>
                        <div>
                          <label class="user-label">
                            {{
                              changeRoleView(
                                currentRole ? currentRole.role : ""
                              )
                            }}</label
                          >
                        </div>
                        <div>
                          <label class="user-label"> {{ jwt.email }}</label>
                        </div>
                        <div>
                          <label class="user-label">ID: {{ jwt.id }}</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    @click.stop=""
                    @touchstart.stop=""
                    class="user_inner_dropdown_inner"
                  >
                    <transition name="user_inner_dropdown">
                      <ul
                        v-show="userInnerDropdown"
                        class="user_inner_dropdown_inner_list"
                      >
                        <li class="counter-account">
                          {{ identitationsLength }} Identităţi disponibile
                        </li>
                        <li
                          v-for="(rol, index) in identitations"
                          :key="rol.id"
                          @click="changeRole(rol)"
                          :class="{ itm_active: rol.isActive }"
                          class="user_inner_dropdown_inner_item"
                          :style="
                            index === rolesLastIndex
                              ? 'margin-bottom:unset;'
                              : ''
                          "
                        >
                          <div class="user_inner_avatar">
                            <span v-if="photoCache[rol.institutionId] === true">
                              <i class="fas fa-sync fa-spin"></i>
                            </span>
                            <img
                              v-else
                              :src="
                                (rol.showUserAvatar
                                  ? USER_AVATAR
                                  : photoCache[rol.institutionId]) ||
                                  Placeholder
                              "
                              alt="Avatar"
                            />
                          </div>
                          <div class="user_inner_info">
                            <div
                              class="user_inner_info_email"
                              v-tooltip="rol.label"
                            >
                              {{ rol.label }}
                            </div>
                            <div>
                              <i>{{ rol.sublabel }}</i>
                            </div>
                          </div>
                        </li>
                        <li
                          style="position: sticky;bottom: 0;background-color: #fff;box-shadow: 0px -2px 15px 0px rgba(0,0,0, 0.1);"
                        >
                          <router-link
                            class="user_inner_dropdown_inner_logout"
                            to="/logout"
                          >
                            <div class="user_inner_dropdown_inner_item_ico">
                              <i class="fas fa-sign-out-alt"></i>
                            </div>
                            <span style="margin-left: 0.4rem">Deconectare</span>
                          </router-link>
                        </li>
                      </ul>
                    </transition>
                  </div>
                </div>
              </div>
            </template>
            <div
              v-else
              class="col-lg-10 col-md-10 col-sm-9 col-xs-3"
              style="padding-top: 19px;padding-left: 28px;color: #fff;"
            >
              {{ $route.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isPublicPage" class="row footer_bar hidden-md hidden-lg">
      <div
        @click="changeMobileStatus(1)"
        :class="`footer_menu text-center col-xs-${showSidebarBtn ? 6 : 12}`"
      >
        <i class="fas fa-bars nav_bars" style="display: inline;"
          ><span class="nav_label">Meniu</span></i
        >
      </div>
      <div
        v-if="showSidebarBtn"
        @click="changeMobileStatus(2)"
        class="footer_sidebar col-xs-4 text-center"
      >
        <i
          class="fas fa-sign-out-alt sidebar_toogle"
          style="display: inline-block;"
          ><span class="nav_label">Sidebar</span></i
        >
      </div>
    </div>
  </div>
</template>

<script>
import PermisionsMixin from "@/mixins/allPermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import FileManager from "@/mixins/fileManager.js";
import { INSTITUTION_DOWNLOAD } from "@/api.js";
import { mapGetters, mapMutations } from "vuex";
import Notification from "@/components/Notification";

import Placeholder from "@/assets/media/user_placeholder_default.png";

const localStoragePhotoCacheKey = "rolesPhotoCache";

export default {
  mixins: [PermisionsMixin, updateRoleStatus, FileManager],
  components: {
    notification: Notification,
  },
  props: {
    showSidebarBtn: {
      type: Boolean,
      default: false,
    },
    hideMobileSidebar: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      Placeholder,
      jwt: this.$jwt.decode(this.$jwt.getToken()) || {
        _NULL: true,
        last_name: "-",
        first_name: "-",
        email: "-",
        avatar: "-",
        userRoles: [],
      },
      currentRole: {},
      userInnerDropdown: false,
      userRoles: [],
      currentRoleOrigin: "...",
      showAdministrationButton: false,
      showAdminDropdown: false,
      showProjects: String(process.env.VUE_APP_PROIECTE) == "true",
      showAchizitions: String(process.env.VUE_APP_ACHIZITII) == "true",
      mobileMenuStatus: false,
      modalOpen: false,
      canNomenclatory: false,
      modalData: null,
      photoCache: {},
      navbarElements: [
        {
          name: "Planificare",
          show: [
            "sef_institutie",
            "supervisor",
            "functionar",
            "responsabil_achizitii",
            "supervizor_achizitii",
            "responsabil_buget",
          ],
          mainKey: "products",
          secondKey: "needs",
          mainLink: "/planificare/catalog",
          secondLink: "/planificare/nevoi",
        },
        {
          name: "Atribuire",
          show: [
            "sef_institutie",
            "responsabil_achizitii",
            "supervizor_achizitii",
          ],
          mainLink: "/atribuire/procedura/achizitie_directa",
        },
        {
          name: "Evaluare",
          show: [
            "sef_institutie",
            "supervisor",
            "functionar",
            "responsabil_achizitii",
            "supervizor_achizitii",
            "responsabil_achizitii_extern",
            "responsabil_buget",
          ],
          mainLink: "/evaluare/in_curand/4",
        },
        {
          name: "Monitorizare",
          show: [
            "sef_institutie",
            "supervisor",
            "functionar",
            "responsabil_achizitii",
            "supervizor_achizitii",
            "responsabil_achizitii_extern",
            "responsabil_buget",
          ],
          mainLink: "/monitorizare/proiecte",
        },
        {
          name: "Administrare",
          show: ["superadmin", "admin_institutie", "responsabil_achizitii"],
          mainLink:
            this.getUserRole() == "superadmin"
              ? "/administrare/catalog"
              : "/administrare/institutie",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userRole", "USER_AVATAR"]),
    isPublicPage() {
      return !this.$route.meta?.needAuth && !this.userRole;
    },
    showAdminPanel() {
      const findDesactivated = Object.values(this.PERMISIONS).filter(
        (e) => e === undefined
      );
      if (findDesactivated?.length) {
        return true;
      }
      return false;
    },
    rolesLastIndex() {
      const x = this.jwt?.userRoles?.length;
      return Number.isInteger(x) ? x - 1 : -1;
    },
    roles() {
      const x = this.jwt?.userRoles;
      if (!Array.isArray(x)) return [];

      return x
        .map((e) => ({
          ...e,
          label: e.institutionName || this.changeRoleView(e.role),
          id: e.id ?? this.makeid(8),
          isActive: e?.id === this.currentRole?.id,
          isValid: Number.isInteger(e.id),
          _role: this.changeRoleView(e.role),
          showUserAvatar: e.role == "superadmin",
        }))
        .filter((e) => e.isValid);
    },
    identitationsLength() {
      return this.roles.length;
    },
    identitations() {
      const prepareSublabel = (instId) => {
        if (!Number.isInteger(instId)) return "";

        const findNr = this.roles.filter(
          (i) => instId === i?.institutionId && i.userRoleStatus == "active"
        ).length;

        return `${findNr} ${findNr === 1 ? "rol activ" : "roluri active"}`;
      };

      return this.removeArrDuplicatesWithkeys(this.roles, [
        "institutionId",
      ]).map((e) => ({
        ...e,
        isActive: e?.institutionId === this.currentRole?.institutionId,
        sublabel: prepareSublabel(e?.institutionId),
      }));
    },
  },
  methods: {
    ...mapMutations({
      clearPopup: "setWindow",
      deleteNeedsData: "setNeedsTableData",
      delProductData: "setProductData",
      delDep: "setDepartmentData",
      setGlobalRole: "setUserRole",
    }),
    setModal(data) {
      if (Array.isArray(data)) {
        this.modalData = data;
        this.modalOpen = true;
      } else {
        this.modalOpen = false;
      }
    },
    changeMobileStatus(state) {
      const x = this.mobileMenuStatus;

      this.mobileMenuStatus = x === state ? false : state;
    },
    onClose() {
      this.userInnerDropdown = false;
    },
    closeAdminDropdown() {
      this.showAdminDropdown = false;
    },
    upFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : "...";
    },
    updateCanNomenclatory() {
      const roleMap = ["superadmin", "admin_institutie"];
      if (roleMap.includes(this.getUserRole())) {
        this.canNomenclatory = true;
        return;
      }
      this.canNomenclatory = false;
    },
    prepareRoleOrigin(role) {
      let origin = [];
      const showInstitutionFor = [
        "sef_institutie",
        "admin_institutie",
        "admin",
      ];

      if (role?.institutionName && showInstitutionFor.includes(role.role)) {
        origin.push(this.upFirstLetter(String(role.institutionName).trim()));
      }
      if (role?.departmentName && !origin.length) {
        origin.push(this.upFirstLetter(String(role.departmentName).trim()));
      }
      return origin.join(" - ");
    },
    changeRole(role, confirmChange = false) {
      const roleId = role?.id;
      if (!Number.isInteger(roleId)) return;
      if (role.userRoleStatus != "active" && confirmChange) {
        this.$toastr.w("Rolul selectat nu este activat.");
        return;
      }

      localStorage.removeItem("needsCart");
      this.deleteNeedsData([]);
      this.delProductData([]);
      this.delDep([]);
      this.clearPopup([]);

      if (!confirmChange) {
        this.onClose();
        const inst = role.institutionId;

        if (Number.isInteger(inst)) {
          const findRoles = this.roles.filter((e) => e.institutionId === inst);

          this.setModal(findRoles);
          return;
        }
      }
      this.setModal();

      const root = this.$root?.$el || null;
      root?.classList.add("load");
      localStorage.setItem("currentUserRole", roleId);
      setTimeout(() => {
        this.currentRole = window.$getRole();
        if (this.currentRole) {
          this.setGlobalRole(this.currentRole);
          this.currentRoleOrigin = this.prepareRoleOrigin(this.currentRole);
        }

        window.requestAnimationFrame(() => {
          root?.classList.remove("load");
          this.goToHomePage();
        });
      }, 200);
    },
    syncPhotos() {
      let localStorageCache = localStorage.getItem(localStoragePhotoCacheKey);
      if (localStorageCache) {
        try {
          localStorageCache = JSON.parse(localStorageCache);
        } catch (err) {}
      } else {
        localStorageCache = {};
      }

      const setImg = (id, base64) =>
        this.$set(this.photoCache, id, base64 || false);

      const error = (id, err) => {
        if (err) {
          console.error(err);
        }
        if (Number.isInteger(id)) {
          setImg(id, false);
        }
      };
      const readResponseFn = async (id, blob) => {
        if (!this.invalidBlob(blob)) {
          const prepare = await this.readAsDataUrl(blob);

          setImg(id, prepare);
        } else {
          error(id);
        }
      };

      for (let e of this.identitations) {
        const id = e.institutionId;
        if (!Number.isInteger(id)) {
          continue;
        }
        const x = localStorageCache[id];

        if (x && x != true) {
          setImg(id, localStorageCache[id]);
          continue;
        }

        setImg(id, true);
        INSTITUTION_DOWNLOAD(id)
          .then((res) => readResponseFn(id, res))
          .catch((err) => error(id, err));
      }
    },
  },
  watch: {
    userRole() {
      this.initAllPermisions();
      this.updateCanNomenclatory();
    },
    modalOpen(x) {
      if (!x) {
        this.modalData = null;
      }
    },
    hideMobileSidebar() {
      this.mobileMenuStatus = false;
    },
    mobileMenuStatus(state) {
      const x = parseInt(state) || false;

      this.$emit("viewMobileSidebar", x === 2);
    },
    photoCache: {
      handler(val) {
        val = { ...val };
        for (let key in val) {
          if (!val[key]) {
            delete val[key];
          }
        }
        localStorage.setItem(localStoragePhotoCacheKey, JSON.stringify(val));
      },
      deep: true,
    },
    $route() {
      this.mobileMenuStatus = false;

      const localRole = parseInt(localStorage.getItem("currentUserRole"));
      if (localRole && localRole != this.currentRole.id) {
        this.changeRole(localRole);
      }
    },
  },
  created() {
    this.initAllPermisions();
    this.updateRoleStatus();
    this.updateCanNomenclatory();
    this.currentRole = window.$getRoleAll();
    if (this.currentRole) {
      this.setGlobalRole(Object.assign(this.PERMISIONS, this.currentRole));
    }
    this.currentRoleOrigin = this.prepareRoleOrigin(this.currentRole);

    this.syncPhotos();

    window.addEventListener("storage", (val) => {
      const x = +val.newValue;
      if (Number.isInteger(x)) {
        this.changeRole(x);
      }
    });
  },
};
</script>

<style>
.RoleIsDisabled {
  pointer-events: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-style: italic;
}
.list_Dropdown-enter-active,
.list_Dropdown-leave-active {
  transition: opacity 0.2s, transform 0.3s;
}
.list_Dropdown-enter {
  opacity: 0;
  transform: translate(-15%, -20%) rotate(10deg) scale(0.9) skewY(-20deg);
}
.list_Dropdown-leave-to {
  opacity: 0;
  transform: translateY(-17%);
}
.pre-header-container {
  font-weight: 300;
}
</style>
<style scoped>
#dropdownMenu1:before {
  left: -15px !important;
}
.nav.navbar-nav {
  padding-right: 0 !important;
}
</style>

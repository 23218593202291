export default {
  data() {
    return {
      intervals: [],
      currentDateAndTime: "",
      currentDate: "",
      currentTime: "",
    }
  },
  methods: {
    displayCurrentDateAndTime() {
      if (this.intervals.find((e) => e[0] == 1 && e[1])) {
        return
      }
      this.currentDateAndTime = window.$getDateAndTime() || ""
      const starter = new Date().getMilliseconds()
      setTimeout(() => {
        this.intervals.push([
          1,
          setInterval(() => {
            this.currentDateAndTime = window.$getDateAndTime() || ""
          }, 1000),
        ])
      }, 1000 - starter)
    },
    clearCurrentTimeIntervals() {
      this.intervals.forEach((listener) => {
        clearInterval(listener[1])
      })
      this.intervals.splice(0, this.intervals.length)
    },
  },
  beforeDestroy() {
    this.clearCurrentTimeIntervals()
  },
}

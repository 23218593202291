function getTitle (vm) {
  const { $Title } = vm.$options
  if($Title) {
    return typeof $Title === 'function'
      ? $Title.call(vm)
      : $Title
  }
}
export default {
  created () {
    const $Title = getTitle(this)
    if($Title) {
      document.title = $Title
    }
  }
}
!(function(t, e) {
  "object" == typeof exports && "object" == typeof module
    ? (module.exports = e())
    : "function" == typeof define && define.amd
    ? define([], e)
    : "object" == typeof exports
    ? (exports.VueSelect = e())
    : (t.VueSelect = e())
})("undefined" != typeof self ? self : this, function() {
  return (function(t) {
    var e = {}

    function n(o) {
      if (e[o]) return e[o].exports
      var i = (e[o] = {
        i: o,
        l: !1,
        exports: {},
      })
      return t[o].call(i.exports, i, i.exports, n), (i.l = !0), i.exports
    }
    return (
      (n.m = t),
      (n.c = e),
      (n.d = function(t, e, o) {
        n.o(t, e) ||
          Object.defineProperty(t, e, {
            enumerable: !0,
            get: o,
          })
      }),
      (n.r = function(t) {
        "undefined" != typeof Symbol &&
          Symbol.toStringTag &&
          Object.defineProperty(t, Symbol.toStringTag, {
            value: "Module",
          }),
          Object.defineProperty(t, "__esModule", {
            value: !0,
          })
      }),
      (n.t = function(t, e) {
        if ((1 & e && (t = n(t)), 8 & e)) return t
        if (4 & e && "object" == typeof t && t && t.__esModule) return t
        var o = Object.create(null)
        if (
          (n.r(o),
          Object.defineProperty(o, "default", {
            enumerable: !0,
            value: t,
          }),
          2 & e && "string" != typeof t)
        )
          for (var i in t)
            n.d(
              o,
              i,
              function(e) {
                return t[e]
              }.bind(null, i)
            )
        return o
      }),
      (n.n = function(t) {
        var e =
          t && t.__esModule
            ? function() {
                return t.default
              }
            : function() {
                return t
              }
        return n.d(e, "a", e), e
      }),
      (n.o = function(t, e) {
        return Object.prototype.hasOwnProperty.call(t, e)
      }),
      (n.p = "/"),
      n((n.s = 8))
    )
  })([
    function(t, e, n) {
      var o = n(4),
        i = n(5),
        s = n(6)
      t.exports = function(t) {
        return o(t) || i(t) || s()
      }
    },
    function(t, e) {
      function n(e) {
        return (
          "function" == typeof Symbol && "symbol" == typeof Symbol.iterator
            ? (t.exports = n = function(t) {
                return typeof t
              })
            : (t.exports = n = function(t) {
                return t &&
                  "function" == typeof Symbol &&
                  t.constructor === Symbol &&
                  t !== Symbol.prototype
                  ? "symbol"
                  : typeof t
              }),
          n(e)
        )
      }
      t.exports = n
    },
    function(t, e, n) {},
    function(t, e) {
      t.exports = function(t, e, n) {
        return (
          e in t
            ? Object.defineProperty(t, e, {
                value: n,
                enumerable: !0,
                configurable: !0,
                writable: !0,
              })
            : (t[e] = n),
          t
        )
      }
    },
    function(t, e) {
      t.exports = function(t) {
        if (Array.isArray(t)) {
          for (var e = 0, n = new Array(t.length); e < t.length; e++)
            n[e] = t[e]
          return n
        }
      }
    },
    function(t, e) {
      t.exports = function(t) {
        if (
          Symbol.iterator in Object(t) ||
          "[object Arguments]" === Object.prototype.toString.call(t)
        )
          return Array.from(t)
      }
    },
    function(t, e) {
      t.exports = function() {
        throw new TypeError("Invalid attempt to spread non-iterable instance")
      }
    },
    function(t, e, n) {
      "use strict"
      var o = n(2)
      n.n(o).a
    },
    function(t, e, n) {
      "use strict"
      n.r(e)
      var o = n(0),
        i = n.n(o),
        s = n(1),
        r = n.n(s),
        a = n(3),
        l = n.n(a),
        c = {
          props: {
            autoscroll: {
              type: Boolean,
              default: !0,
            },
          },
          watch: {
            typeAheadPointer: function() {
              this.autoscroll && this.maybeAdjustScroll()
            },
          },
          methods: {
            maybeAdjustScroll: function() {
              var t,
                e =
                  (null === (t = this.$refs.dropdownMenu) || void 0 === t
                    ? void 0
                    : t.children[this.typeAheadPointer]) || !1
              if (e) {
                var n = this.getDropdownViewport(),
                  o = e.getBoundingClientRect(),
                  i = o.top,
                  s = o.bottom,
                  r = o.height
                if (i < n.top)
                  return (this.$refs.dropdownMenu.scrollTop = e.offsetTop)
                if (s > n.bottom)
                  return (this.$refs.dropdownMenu.scrollTop =
                    e.offsetTop - (n.height - r))
              }
            },
            getDropdownViewport: function() {
              return this.$refs.dropdownMenu
                ? this.$refs.dropdownMenu.getBoundingClientRect()
                : {
                    height: 0,
                    top: 0,
                    bottom: 0,
                  }
            },
          },
        },
        u = {
          data: function() {
            return {
              typeAheadPointer: -1,
            }
          },
          watch: {
            filteredOptions: function() {
              this.filteredOptions.length
                ? this.$emit("noEmpty")
                : this.$emit("empty")
              for (var t = 0; t < this.filteredOptions.length; t++)
                if (this.selectable(this.filteredOptions[t])) {
                  this.typeAheadPointer = t
                  break
                }
            },
          },
          methods: {
            typeAheadUp: function() {
              for (var t = this.typeAheadPointer - 1; t >= 0; t--)
                if (this.selectable(this.filteredOptions[t])) {
                  this.typeAheadPointer = t
                  break
                }
            },
            typeAheadDown: function() {
              for (
                var t = this.typeAheadPointer + 1;
                t < this.filteredOptions.length;
                t++
              )
                if (this.selectable(this.filteredOptions[t])) {
                  this.typeAheadPointer = t
                  break
                }
            },
            typeAheadSelect: function() {
              var t = this.filteredOptions[this.typeAheadPointer]
              t && this.select(t)
            },
          },
        },
        p = {
          props: {
            loading: {
              type: Boolean,
              default: !1,
            },
          },
          data: function() {
            return {
              mutableLoading: !1,
            }
          },
          watch: {
            search: function() {
              this.search = this.inputOnly(this.search)
              this.$emit("search", this.search, this.toggleLoading)
            },
            loading: function(t) {
              this.mutableLoading = t
            },
          },
          methods: {
            toggleLoading: function() {
              var t =
                arguments.length > 0 && void 0 !== arguments[0]
                  ? arguments[0]
                  : null
              return (this.mutableLoading =
                null == t ? !this.mutableLoading : t)
            },
          },
        }

      function h(t, e, n, o, i, s, r, a) {
        var l,
          c = "function" == typeof t ? t.options : t
        if (
          (e && ((c.render = e), (c.staticRenderFns = n), (c._compiled = !0)),
          o && (c.functional = !0),
          s && (c._scopeId = "data-v-" + s),
          r
            ? ((l = function(t) {
                ;(t =
                  t ||
                  (this.$vnode && this.$vnode.ssrContext) ||
                  (this.parent &&
                    this.parent.$vnode &&
                    this.parent.$vnode.ssrContext)) ||
                  "undefined" == typeof __VUE_SSR_CONTEXT__ ||
                  (t = __VUE_SSR_CONTEXT__),
                  i && i.call(this, t),
                  t && t._registeredComponents && t._registeredComponents.add(r)
              }),
              (c._ssrRegister = l))
            : i &&
              (l = a
                ? function() {
                    i.call(this, this.$root.$options.shadowRoot)
                  }
                : i),
          l)
        )
          if (c.functional) {
            c._injectStyles = l
            var u = c.render
            c.render = function(t, e) {
              return l.call(e), u(t, e)
            }
          } else {
            var p = c.beforeCreate
            c.beforeCreate = p ? [].concat(p, l) : [l]
          }
        return {
          exports: t,
          options: c,
        }
      }
      var d = {
          Deselect: h(
            {},
            function() {
              var t = this.$createElement,
                e = this._self._c || t
              return e(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "10",
                    height: "10",
                  },
                },
                [
                  e("path", {
                    attrs: {
                      d:
                        "M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z",
                    },
                  }),
                ]
              )
            },
            [],
            !1,
            null,
            null,
            null
          ).exports,
          OpenIndicator: h(
            {},
            function() {
              var t = this.$createElement,
                e = this._self._c || t
              return e(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "14",
                    height: "10",
                  },
                },
                [
                  e("path", {
                    attrs: {
                      d:
                        "M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z",
                    },
                  }),
                ]
              )
            },
            [],
            !1,
            null,
            null,
            null
          ).exports,
        },
        f = {
          inserted: function(t, e, n) {
            var o = n.context
            if (o.appendToBody) {
              var i = o.$refs.toggle.getBoundingClientRect(),
                s = i.height,
                r = i.top,
                a = i.left,
                l = i.width,
                c = window.scrollX || window.pageXOffset,
                u = window.scrollY || window.pageYOffset
              ;(t.unbindPosition = o.calculatePosition(t, o, {
                width: l + "px",
                left: c + a + "px",
                top: u + r + s + "px",
              })),
                document.body.appendChild(t)
            }
          },
          unbind: function(t, e, n) {
            n.context.appendToBody &&
              (t.unbindPosition &&
                "function" == typeof t.unbindPosition &&
                t.unbindPosition(),
              t.parentNode && t.parentNode.removeChild(t))
          },
        },
        y = 0

      function g(t, e) {
        var n = Object.keys(t)
        if (Object.getOwnPropertySymbols) {
          var o = Object.getOwnPropertySymbols(t)
          e &&
            (o = o.filter(function(e) {
              return Object.getOwnPropertyDescriptor(t, e).enumerable
            })),
            n.push.apply(n, o)
        }
        return n
      }

      function b(t) {
        for (var e = 1; e < arguments.length; e++) {
          var n = null != arguments[e] ? arguments[e] : {}
          e % 2
            ? g(Object(n), !0).forEach(function(e) {
                l()(t, e, n[e])
              })
            : Object.getOwnPropertyDescriptors
            ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(n))
            : g(Object(n)).forEach(function(e) {
                Object.defineProperty(
                  t,
                  e,
                  Object.getOwnPropertyDescriptor(n, e)
                )
              })
        }
        return t
      }
      var v = {
          components: b({}, d),
          mixins: [c, u, p],
          directives: {
            appendToBody: f,
          },
          props: {
            value: {},
            components: {
              type: Object,
              default: function() {
                return {}
              },
            },
            options: {
              type: Array,
              default: function() {
                return []
              },
            },
            overflowPrevent: {
              type: Boolean,
              default: true,
            },
            maxHeight: {
              type: [Boolean, Number],
              default: false
            },
            disabled: {
              type: Boolean,
              default: !1,
            },
            clearable: {
              type: Boolean,
              default: !0,
            },
            searchable: {
              type: Boolean,
              default: !0,
            },
            multiple: {
              type: Boolean,
              default: !1,
            },
            placeholder: {
              type: String,
              default: "",
            },
            transition: {
              type: String,
              default: "vs__fade",
            },
            clearSearchOnSelect: {
              type: Boolean,
              default: !0,
            },
            closeOnSelect: {
              type: Boolean,
              default: !0,
            },
            label: {
              type: String,
              default: "label",
            },
            autocomplete: {
              type: String,
              default: "off",
            },
            autoTableHorizontal: {
              type: Boolean,
              default: false,
            },
            clearInputAfterBlur: {
              type: Boolean,
              default: false,
            },
            inputOnly: {
              type: Function,
              default: function(val) {
                return val
              },
            },
            reduce: {
              type: Function,
              default: function(t) {
                return t
              },
            },
            emptyLabel: {
              type: String,
              default: "Nu s-au gasit rezultate",
            },
            selectable: {
              type: Function,
              default: function(t) {
                return !0
              },
            },
            getOptionLabel: {
              type: Function,
              default: function(t) {
                return "object" === r()(t)
                  ? t.hasOwnProperty(this.label)
                    ? t[this.label]
                    : console.warn(
                        '[vue-select warn]: Label key "option.'.concat(
                          this.label,
                          '" does not'
                        ) +
                          " exist in options object ".concat(
                            JSON.stringify(t),
                            ".\n"
                          ) +
                          "https://vue-select.org/api/props.html#getoptionlabel"
                      )
                  : t
              },
            },
            getOptionKey: {
              type: Function,
              default: function(t) {
                if ("object" !== r()(t)) return t
                try {
                  return t.hasOwnProperty("id")
                    ? t.id
                    : (function(t) {
                        var e = {}
                        return (
                          Object.keys(t)
                            .sort()
                            .forEach(function(n) {
                              e[n] = t[n]
                            }),
                          JSON.stringify(e)
                        )
                      })(t)
                } catch (e) {
                  return console.warn(
                    "[vue-select warn]: Could not stringify this option to generate unique key. Please provide'getOptionKey' prop to return a unique key for each option.\nhttps://vue-select.org/api/props.html#getoptionkey",
                    t,
                    e
                  )
                }
              },
            },
            onTab: {
              type: Function,
              default: function() {
                this.selectOnTab && !this.isComposing && this.typeAheadSelect()
              },
            },
            taggable: {
              type: Boolean,
              default: !1,
            },
            tabindex: {
              type: Number,
              default: null,
            },
            pushTags: {
              type: Boolean,
              default: !1,
            },
            filterable: {
              type: Boolean,
              default: !0,
            },
            filterBy: {
              type: Function,
              default: function(t, e, n) {
                return (e || "").toLowerCase().indexOf(n.toLowerCase()) > -1
              },
            },
            filter: {
              type: Function,
              default: function(t, e) {
                var n = this
                return t.filter(function(t) {
                  var o = n.getOptionLabel(t)
                  return (
                    "number" == typeof o && (o = o.toString()),
                    n.filterBy(t, o, e)
                  )
                })
              },
            },
            createOption: {
              type: Function,
              default: function(t) {
                return "object" === r()(this.optionList[0])
                  ? l()({}, this.label, t)
                  : t
              },
            },
            resetOnOptionsChange: {
              default: !1,
              validator: function(t) {
                return ["function", "boolean"].includes(r()(t))
              },
            },
            clearSearchOnBlur: {
              type: Function,
              default: function(t) {
                var e = t.clearSearchOnSelect,
                  n = t.multiple
                return e && !n
              },
            },
            noDrop: {
              type: Boolean,
              default: !1,
            },
            inputId: {
              type: String,
            },
            inputClass: {
              type: String,
              default: "",
            },
            dir: {
              type: String,
              default: "auto",
            },
            selectOnTab: {
              type: Boolean,
              default: !1,
            },
            selectOnKeyCodes: {
              type: Array,
              default: function() {
                return [13]
              },
            },
            searchInputQuerySelector: {
              type: String,
              default: "[type=search]",
            },
            mapKeydown: {
              type: Function,
              default: function(t, e) {
                return t
              },
            },
            appendToBody: {
              type: Boolean,
              default: !1,
            },
            calculatePosition: {
              type: Function,
              default: function(t, e, n) {
                var o = n.width,
                  i = n.top,
                  s = n.left
                ;(t.style.top = i), (t.style.left = s), (t.style.width = o)
              },
            },
          },
          data: function() {
            return {
              uid: ++y,
              search: "",
              open: !1,
              isComposing: !1,
              pushedTags: [],
              _value: [],
            }
          },
          watch: {
            options: function(t, e) {
              var n = this
              !this.taggable &&
                ("function" == typeof n.resetOnOptionsChange
                  ? n.resetOnOptionsChange(t, e, n.selectedValue)
                  : n.resetOnOptionsChange) &&
                this.clearSelection(),
                this.value &&
                  this.isTrackingValues &&
                  this.setInternalValueFromOptions(this.value)
            },
            value: function(t) {
              this.isTrackingValues && this.setInternalValueFromOptions(t)
            },
            multiple: function() {
              this.clearSelection()
            },
            open: function(t, opened) {
              this.$emit(t ? "open" : "close")

              if (!t && this.clearInputAfterBlur) {
                this.search = ""
              }
              
              if(opened) return
              
              const id = this.scope.search["attributes"]["aria-controls"]

              const forDelete = document.getElementById(`ID${id}`)
              if (forDelete) {
                forDelete.remove()
              }

              if (this.appendToBody || !this.overflowPrevent && !this.maxHeight) return

              const listbox = document.getElementById(id)
              const vSelect = this.$refs[this.scope.search.attributes.ref]
                .offsetParent.offsetParent

              if (vSelect && listbox) {
                try {
                  let calculate = this.maxHeight ||
                    vSelect.offsetParent.scrollHeight -
                    vSelect.offsetTop -
                    (vSelect.scrollHeight + 10)
                  if (calculate < 35 && !this.maxHeight) {
                    calculate = 350
                  }
                  const sheet = document.createElement("style")
                  sheet.id = `ID${id}`
                  const prepareStyles = [`max-height:${calculate}px;`]

                  sheet.innerHTML = `#${id} {${prepareStyles.join("")}}`
                  document.body.appendChild(sheet)
                } catch (err) {
                  console.warning(err)
                }
              }
            },
          },
          created: function() {
            ;(this.mutableLoading = this.loading),
              void 0 !== this.value &&
                this.isTrackingValues &&
                this.setInternalValueFromOptions(this.value),
              this.$on("option:created", this.pushTag)
          },
          methods: {
            setInternalValueFromOptions: function(t) {
              var e = this
              Array.isArray(t)
                ? (this.$data._value = t.map(function(t) {
                    return e.findOptionFromReducedValue(t)
                  }))
                : (this.$data._value = this.findOptionFromReducedValue(t))
            },
            select: function(t) {
              this.$emit("option:selecting", t),
                this.isOptionSelected(t) ||
                  (this.taggable &&
                    !this.optionExists(t) &&
                    this.$emit("option:created", t),
                  this.multiple && (t = this.selectedValue.concat(t)),
                  this.updateValue(t),
                  this.$emit("option:selected", t)),
                this.onAfterSelect(t)
            },
            deselect: function(t) {
              var e = this
              this.$emit("option:deselecting", t),
                this.updateValue(
                  this.selectedValue.filter(function(n) {
                    return !e.optionComparator(n, t)
                  })
                ),
                this.$emit("option:deselected", t)
            },
            clearSelection: function() {
              this.$emit('option:clear')
              this.updateValue(this.multiple ? [] : null)
            },
            onAfterSelect: function(t) {
              this.closeOnSelect &&
                ((this.open = !this.open), this.searchEl.blur()),
                this.clearSearchOnSelect && (this.search = "")
            },
            updateValue: function(t) {
              var e = this
              void 0 === this.value && (this.$data._value = t),
                null !== t &&
                  (t = Array.isArray(t)
                    ? t.map(function(t) {
                        return e.reduce(t)
                      })
                    : this.reduce(t)),
                this.$emit("input", t)
            },
            toggleDropdown: function(t) {
              var e = t.target !== this.searchEl
              e && t.preventDefault()
              var n = [].concat(
                i()(this.$refs.deselectButtons || []),
                i()([this.$refs.clearButton] || !1)
              )
              void 0 === this.searchEl ||
              n.filter(Boolean).some(function(e) {
                return e.contains(t.target) || e === t.target
              })
                ? t.preventDefault()
                : this.open && e
                ? this.searchEl.blur()
                : this.disabled || ((this.open = !0), this.searchEl.focus())
            },
            isOptionSelected: function(t) {
              var e = this
              return this.selectedValue.some(function(n) {
                return e.optionComparator(n, t)
              })
            },
            optionComparator: function(t, e) {
              return this.getOptionKey(t) === this.getOptionKey(e)
            },
            findOptionFromReducedValue: function(t) {
              var e = this,
                n = []
                  .concat(i()(this.options), i()(this.pushedTags))
                  .filter(function(n) {
                    return JSON.stringify(e.reduce(n)) === JSON.stringify(t)
                  })
              return 1 === n.length
                ? n[0]
                : n.find(function(t) {
                    return e.optionComparator(t, e.$data._value)
                  }) || t
            },
            closeSearchOptions: function() {
              ;(this.open = !1), this.$emit("search:blur")
            },
            maybeDeleteValue: function() {
              if (
                !this.searchEl.value.length &&
                this.selectedValue &&
                this.selectedValue.length &&
                this.clearable
              ) {
                var t = null
                this.multiple &&
                  (t = i()(
                    this.selectedValue.slice(0, this.selectedValue.length - 1)
                  )),
                  this.updateValue(t)
              }
            },
            optionExists: function(t) {
              var e = this
              return this.optionList.some(function(n) {
                return e.optionComparator(n, t)
              })
            },
            normalizeOptionForSlot: function(t) {
              return "object" === r()(t) ? t : l()({}, this.label, t)
            },
            pushTag: function(t) {
              this.pushedTags.push(t)
            },
            onEscape: function() {
              this.search.length ? (this.search = "") : this.searchEl.blur()
            },
            onSearchBlur: function() {
              if (!this.mousedown || this.searching) {
                var t = this.clearSearchOnSelect,
                  e = this.multiple
                return (
                  this.clearSearchOnBlur({
                    clearSearchOnSelect: t,
                    multiple: e,
                  }) && (this.search = ""),
                  void this.closeSearchOptions()
                )
              }
              ;(this.mousedown = !1),
                0 !== this.search.length ||
                  0 !== this.options.length ||
                  this.closeSearchOptions()
            },
            onSearchFocus: function() {
              ;(this.open = !0), this.$emit("search:focus")
            },
            onMousedown: function() {
              this.mousedown = !0
            },
            onMouseUp: function() {
              this.mousedown = !1
            },
            onSearchKeyDown: function(t) {
              var e = this,
                n = function(t) {
                  return (
                    t.preventDefault(), !e.isComposing && e.typeAheadSelect()
                  )
                },
                o = {
                  8: function(t) {
                    return e.maybeDeleteValue()
                  },
                  9: function(t) {
                    return e.onTab()
                  },
                  27: function(t) {
                    return e.onEscape()
                  },
                  38: function(t) {
                    return t.preventDefault(), e.typeAheadUp()
                  },
                  40: function(t) {
                    return t.preventDefault(), e.typeAheadDown()
                  },
                }
              this.selectOnKeyCodes.forEach(function(t) {
                return (o[t] = n)
              })
              var i = this.mapKeydown(o, this)
              if ("function" == typeof i[t.keyCode]) return i[t.keyCode](t)
            },
          },
          computed: {
            isTrackingValues: function() {
              return (
                void 0 === this.value ||
                this.$options.propsData.hasOwnProperty("reduce")
              )
            },
            selectedValue: function() {
              var t = this.value
              return (
                this.isTrackingValues && (t = this.$data._value),
                t ? [].concat(t) : []
              )
            },
            optionList: function() {
              return this.options.concat(this.pushTags ? this.pushedTags : [])
            },
            searchEl: function() {
              return this.$scopedSlots.search
                ? this.$refs.selectedOptions.querySelector(
                    this.searchInputQuerySelector
                  )
                : this.$refs.search
            },
            scope: function() {
              var t = this,
                e = {
                  search: this.search,
                  loading: this.loading,
                  searching: this.searching,
                  filteredOptions: this.filteredOptions,
                }
              return {
                search: {
                  attributes: b(
                    {
                      disabled: this.disabled,
                      placeholder: this.searchPlaceholder,
                      tabindex: this.tabindex,
                      readonly: !this.searchable,
                      id: this.inputId,
                      "aria-autocomplete": "list",
                      "aria-labelledby": "vs".concat(this.uid, "__combobox"),
                      "aria-controls": "vs".concat(this.uid, "__listbox"),
                      ref: "search",
                      type: "search",
                      autocomplete: this.autocomplete,
                      value: this.search,
                    },
                    this.dropdownOpen &&
                      this.filteredOptions[this.typeAheadPointer]
                      ? {
                          "aria-activedescendant": "vs"
                            .concat(this.uid, "__option-")
                            .concat(this.typeAheadPointer),
                        }
                      : {}
                  ),
                  events: {
                    compositionstart: function() {
                      return (t.isComposing = !0)
                    },
                    compositionend: function() {
                      return (t.isComposing = !1)
                    },
                    keydown: this.onSearchKeyDown,
                    blur: this.onSearchBlur,
                    focus: this.onSearchFocus,
                    input: function(e) {
                      return (t.search = e.target.value)
                    },
                  },
                },
                spinner: {
                  loading: this.mutableLoading,
                },
                noOptions: {
                  search: this.search,
                  loading: this.loading,
                  searching: this.searching,
                },
                openIndicator: {
                  attributes: {
                    ref: "openIndicator",
                    role: "presentation",
                    class: "vs__open-indicator",
                  },
                },
                listHeader: e,
                listFooter: e,
                header: b({}, e, {
                  deselect: this.deselect,
                }),
                footer: b({}, e, {
                  deselect: this.deselect,
                }),
              }
            },
            childComponents: function() {
              return b({}, d, {}, this.components)
            },
            stateClasses: function() {
              return {
                "vs--open": this.dropdownOpen,
                "vs--single": !this.multiple,
                "vs--searching": this.searching && !this.noDrop,
                "vs--searchable": this.searchable && !this.noDrop,
                "vs--unsearchable": !this.searchable,
                "vs--loading": this.mutableLoading,
                "vs--disabled": this.disabled,
              }
            },
            searching: function() {
              return this.$emit("inputing", this.search), !!this.search
            },
            dropdownOpen: function() {
              return !this.noDrop && this.open && !this.mutableLoading
            },
            searchPlaceholder: function() {
              if (this.isValueEmpty && this.placeholder) return this.placeholder
            },
            filteredOptions: function() {
              var t = [].concat(this.optionList)
              if (!this.filterable && !this.taggable) return t
              var e = this.search.length ? this.filter(t, this.search, this) : t
              if (this.taggable && this.search.length) {
                var n = this.createOption(this.search)
                this.optionExists(n) || e.unshift(n)
              }
              return e
            },
            isValueEmpty: function() {
              return 0 === this.selectedValue.length
            },
            showClearButton: function() {
              return (
                !this.multiple &&
                this.clearable &&
                !this.open &&
                !this.isValueEmpty
              )
            },
          },
        },
        m =
          (n(7),
          h(
            v,
            function() {
              var t = this,
                e = t.$createElement,
                n = t._self._c || e
              return n(
                "div",
                {
                  staticClass: "v-select",
                  class: t.stateClasses,
                  attrs: {
                    dir: t.dir,
                  },
                },
                [
                  t._t("header", null, null, t.scope.header),
                  t._v(" "),
                  n(
                    "div",
                    {
                      ref: "toggle",
                      staticClass: "vs__dropdown-toggle",
                      attrs: {
                        id: "vs" + t.uid + "__combobox",
                        role: "combobox",
                        "aria-expanded": t.dropdownOpen.toString(),
                        "aria-owns": "vs" + t.uid + "__listbox",
                        "aria-label": "Search for option",
                      },
                      on: {
                        mousedown: function(e) {
                          return t.toggleDropdown(e)
                        },
                      },
                    },
                    [
                      n(
                        "div",
                        {
                          ref: "selectedOptions",
                          staticClass: "vs__selected-options",
                        },
                        [
                          t._l(t.selectedValue, function(e) {
                            return t._t(
                              "selected-option-container",
                              [
                                n(
                                  "span",
                                  {
                                    key: t.getOptionKey(e),
                                    staticClass: "vs__selected",
                                  },
                                  [
                                    t._t(
                                      "selected-option",
                                      [
                                        t._v(
                                          "\n            " +
                                            t._s(t.getOptionLabel(e)) +
                                            "\n          "
                                        ),
                                      ],
                                      null,
                                      t.normalizeOptionForSlot(e)
                                    ),
                                    t._v(" "),
                                    t.multiple
                                      ? n(
                                          "button",
                                          {
                                            ref: "deselectButtons",
                                            refInFor: !0,
                                            staticClass: "vs__deselect",
                                            attrs: {
                                              disabled: t.disabled,
                                              type: "button",
                                              title:
                                                "Deselect " +
                                                t.getOptionLabel(e),
                                              "aria-label":
                                                "Deselect " +
                                                t.getOptionLabel(e),
                                            },
                                            on: {
                                              click: function(n) {
                                                return t.deselect(e)
                                              },
                                            },
                                          },
                                          [
                                            n(t.childComponents.Deselect, {
                                              tag: "component",
                                            }),
                                          ],
                                          1
                                        )
                                      : t._e(),
                                  ],
                                  2
                                ),
                              ],
                              {
                                option: t.normalizeOptionForSlot(e),
                                deselect: t.deselect,
                                multiple: t.multiple,
                                disabled: t.disabled,
                              }
                            )
                          }),
                          t._v(" "),
                          t._t(
                            "search",
                            [
                              n(
                                "input",
                                t._g(
                                  t._b(
                                    {
                                      staticClass: "vs__search ".concat(
                                        this.inputClass
                                      ),
                                    },
                                    "input",
                                    t.scope.search.attributes,
                                    !1
                                  ),
                                  t.scope.search.events
                                )
                              ),
                            ],
                            null,
                            t.scope.search
                          ),
                        ],
                        2
                      ),
                      t._v(" "),
                      n(
                        "div",
                        {
                          ref: "actions",
                          staticClass: "vs__actions",
                        },
                        [
                          n(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: t.showClearButton,
                                  expression: "showClearButton",
                                },
                              ],
                              ref: "clearButton",
                              staticClass: "vs__clear",
                              attrs: {
                                disabled: t.disabled,
                                type: "button",
                                title: "Elimină selecția",
                                "aria-label": "Clear Selected",
                              },
                              on: {
                                click: t.clearSelection,
                              },
                            },
                            [
                              n(t.childComponents.Deselect, {
                                tag: "component",
                              }),
                            ],
                            1
                          ),
                          t._v(" "),
                          t._t(
                            "open-indicator",
                            [
                              t.noDrop
                                ? t._e()
                                : n(
                                    t.childComponents.OpenIndicator,
                                    t._b(
                                      {
                                        tag: "component",
                                      },
                                      "component",
                                      t.scope.openIndicator.attributes,
                                      !1
                                    )
                                  ),
                            ],
                            null,
                            t.scope.openIndicator
                          ),
                          t._v(" "),
                          t._t(
                            "spinner",
                            [
                              n(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: t.mutableLoading,
                                      expression: "mutableLoading",
                                    },
                                  ],
                                  staticClass: "vs__spinner",
                                },
                                [t._v("Loading...")]
                              ),
                            ],
                            null,
                            t.scope.spinner
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                  t._v(" "),
                  n(
                    "transition",
                    {
                      attrs: {
                        name: t.transition,
                      },
                    },
                    [
                      t.dropdownOpen
                        ? n(
                            "ul",
                            {
                              directives: [
                                {
                                  name: "append-to-body",
                                  rawName: "v-append-to-body",
                                },
                              ],
                              key: "vs" + t.uid + "__listbox",
                              ref: "dropdownMenu",
                              staticClass: "vs__dropdown-menu",
                              attrs: {
                                id: "vs" + t.uid + "__listbox",
                                role: "listbox",
                                tabindex: "-1",
                              },
                              on: {
                                mousedown: function(e) {
                                  return e.preventDefault(), t.onMousedown(e)
                                },
                                mouseup: t.onMouseUp,
                              },
                            },
                            [
                              t._t(
                                "list-header",
                                null,
                                null,
                                t.scope.listHeader
                              ),
                              t._v(" "),
                              t._l(t.filteredOptions, function(e, o) {
                                return n(
                                  "li",
                                  {
                                    key: t.getOptionKey(e),
                                    staticClass: "vs__dropdown-option",
                                    class: {
                                      "vs__dropdown-option--selected": t.isOptionSelected(
                                        e
                                      ),
                                      "vs__dropdown-option--highlight":
                                        o === t.typeAheadPointer,
                                      "vs__dropdown-option--disabled": !t.selectable(
                                        e
                                      ),
                                    },
                                    attrs: {
                                      role: "option",
                                      id: "vs" + t.uid + "__option-" + o,
                                      "aria-selected":
                                        o === t.typeAheadPointer || null,
                                    },
                                    on: {
                                      mouseover: function(n) {
                                        t.selectable(e) &&
                                          (t.typeAheadPointer = o)
                                      },
                                      mousedown: function(n) {
                                        n.preventDefault(),
                                          n.stopPropagation(),
                                          t.selectable(e) && t.select(e)
                                      },
                                    },
                                  },
                                  [
                                    t._t(
                                      "option",
                                      [
                                        t._v(
                                          "\n          " +
                                            t._s(t.getOptionLabel(e)) +
                                            "\n        "
                                        ),
                                      ],
                                      null,
                                      t.normalizeOptionForSlot(e)
                                    ),
                                  ],
                                  2
                                )
                              }),
                              t._v(" "),
                              0 === t.filteredOptions.length
                                ? n(
                                    "li",
                                    {
                                      staticClass: "vs__no-options",
                                    },
                                    [
                                      t._t(
                                        "no-options",
                                        [t._v(this.emptyLabel)],
                                        null,
                                        t.scope.noOptions
                                      ),
                                    ],
                                    2
                                  )
                                : t._e(),
                              t._v(" "),
                              t._t(
                                "list-footer",
                                null,
                                null,
                                t.scope.listFooter
                              ),
                            ],
                            2
                          )
                        : n("ul", {
                            staticStyle: {
                              display: "none",
                              visibility: "hidden",
                            },
                            attrs: {
                              id: "vs" + t.uid + "__listbox",
                              role: "listbox",
                            },
                          }),
                    ]
                  ),
                  t._v(" "),
                  t._t("footer", null, null, t.scope.footer),
                ],
                2
              )
            },
            [],
            !1,
            null,
            null,
            null
          ).exports),
        _ = {
          ajax: p,
          pointer: u,
          pointerScroll: c,
        }
      n.d(e, "VueSelect", function() {
        return m
      }),
        n.d(e, "mixins", function() {
          return _
        }),
        (e.default = m)
    },
  ])
})

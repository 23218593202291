import { USER_AVATAR_DOWNLOAD } from '@/api.js'
import { mapMutations } from 'vuex'
import avatarPlaceholder from '@/assets/media/avatar-1577909_960_720-min.png'

export default {
  methods: {
    ...mapMutations({setUserAvatar:'setUserAvatar'}),
    async initUserAvatar() {
      const findAvatar = localStorage.getItem('USER_AVATAR_BASE64')
      let toReturn = ''
      if(!findAvatar) {
        await USER_AVATAR_DOWNLOAD().then(async (file) => {
          if(!file || file.size < 500 || file.type == 'application/json') {
            toReturn = avatarPlaceholder
            return
          }
          const reader = new FileReader()

          async function load() {
            return await new Promise(resolve => {
            reader.addEventListener('load', (event) => {
                const getBase64 = String(event.target.result)
                localStorage.setItem('USER_AVATAR_BASE64', getBase64)
                resolve(getBase64)
              })
            })
          }
          reader.readAsDataURL(file)
          toReturn = await load()
        }).catch(err => {
          toReturn = avatarPlaceholder
        })
      } else {
        toReturn = findAvatar
      }
      this.setUserAvatar(toReturn)
      return toReturn
    }
  }
}
export default {
  data() {
    return {
      currentRoleStatus: false,
    }
  },
  computed: {
    isUser() {
      return this.currentRoleStatus > 2 ? true : false
    },
    isAdmin() {
      return this.currentRoleStatus > 0 && this.currentRoleStatus < 3
        ? true
        : false
    },
    isSuperadmin() {
      return this.currentRoleStatus === 1 ? true : false
    },
  },
  methods: {
    updateRoleStatus(val) {
      const findRole = val || window.$getRoleAll()
      if (findRole?.role) {
        const x = String(findRole.role)
          .trim()
          .toLowerCase()
        if (x === 'superadmin') {
          this.currentRoleStatus = 1
        } else if (
          ['admin', 'admin_institutie', 'sef_institutie'].includes(x)
        ) {
          this.currentRoleStatus = 2
        } else {
          this.currentRoleStatus = 3
        }
      }
    },
  },
}
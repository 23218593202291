<template>
  <div id="app" class="Loadable">
    <PdfPreview />
    <Popup
      @Needs="needsManager"
      @Products="productsManager"
      @Cpv="cpvManager"
      @Mu="muManager"
      @Budget="budgetManager"
      @needsCartList="needsCartListManager"
      @Delete_Institution="Delete_Institution"
      @Delete_Department="Delete_Department"
      @ProcedureType="procedureTypeManager"
      @ReferenceReject="deleteReference"
      @NeedReject="deleteNeed"
      @ViewReferenceVersion="ViewReferenceVersion"
      @userList="userList"
      @userStatus="userStatus"
      @Register="Register"
    />
    <component :is="layout" />

    <teleport to='body'>
      <div class="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </teleport>
  </div>
</template>

<style src="./assets/all.css"></style>
<style src="./assets/auth.css"></style>
<style src="./assets/tooltip.css"></style>
<style src="./assets/vue-tabs.css"></style>
<style src="./assets/main.css"></style>
<style>
.vm--container {
  z-index: 1010 !important;
}
.vm--modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: transparent !important;
  box-shadow: unset !important;
}
.table.table-bordered {
  table-layout: fixed !important;
}

.table.table-bordered td {
  word-break: break-all !important;
}
</style>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");

* {
  box-sizing: border-box;
}
html {
  font-size: 8px;
}
html,
body {
  margin: 0;
  padding: 0;

  background-color: #f2f7fc;
  min-height: 100vh;
  overflow-x: hidden;
}
#app {
  font-family: "Roboto", sans-serif;
  font-weight: 400;

  display: block;

  width: 100%;
}
</style>

<script>
import authLayout from "@/layouts/authLayout";
import mainLayout from "@/layouts/mainLayout";
import emptyLayout from "@/layouts/emptyLayout";
import { EXPENSES } from '@/api.js'
import { watch } from "@vue/runtime-dom";

export default {
  components: {
    Popup: () => import("@/components/Popup"),
    PdfPreview: () => import("@/components/PdfPreview"),
    authLayout,
    mainLayout,
    emptyLayout,
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  data() {
    return {
      pop: true,
      products: 1,
      budget: 1,
      needs: 1,
      cpv: 1,
      mu: 1,
      usr: 1,
      register: 1,
      NeedsCartList: 1,
      procedureType: 1,
      Delete_Institution_Id: false,
      referenceReject: false,
      needReject: false,
      Delete_Department_Id: false,
      viewReferenceVersion: false,
      userStat: false,
    };
  },
  provide() {
    const needs = {};
    Object.defineProperty(needs, "reload", {
      enumerable: true,
      get: () => this.needs,
    });
    const needsCartList = {};
    Object.defineProperty(needsCartList, "destroy", {
      enumerable: true,
      get: () => this.NeedsCartList,
    });
    const Delete_Institution = {};
    Object.defineProperty(Delete_Institution, "Institution_id", {
      enumerable: true,
      get: () => this.Delete_Institution_Id,
    });
    const ReferenceReject = {};
    Object.defineProperty(ReferenceReject, "ReferenceReject", {
      enumerable: true,
      get: () => this.referenceReject,
    });
    const NeedRejectData = {};
    Object.defineProperty(NeedRejectData, "NeedRejectData", {
      enumerable: true,
      get: () => this.needReject,
    });
    const Delete_Department = {};
    Object.defineProperty(Delete_Department, "Deparment_id", {
      enumerable: true,
      get: () => this.Delete_Department_Id,
    });
    const products = {};
    Object.defineProperty(products, "reload", {
      enumerable: true,
      get: () => this.products,
    });
    const usr = {};
    Object.defineProperty(usr, "reload", {
      enumerable: true,
      get: () => this.usr,
    });
    const register = {};
    Object.defineProperty(register, "reload", {
      enumerable: true,
      get: () => this.register,
    });
    const budget = {};
    Object.defineProperty(budget, "reload", {
      enumerable: true,
      get: () => this.budget,
    });
    const cpv = {};
    Object.defineProperty(cpv, "reload", {
      enumerable: true,
      get: () => this.cpv,
    });
    const mu = {};
    Object.defineProperty(mu, "reload", {
      enumerable: true,
      get: () => this.mu,
    });
    const procedureType = {};
    Object.defineProperty(procedureType, "reload", {
      enumerable: true,
      get: () => this.procedureType,
    });
    const viewReferenceVersion = {};
    Object.defineProperty(viewReferenceVersion, "reference", {
      enumerable: true,
      get: () => this.viewReferenceVersion,
    });
    const userStat = {};
    Object.defineProperty(userStat, "Ustatus", {
      enumerable: true,
      get: () => this.userStat,
    });

    return {
      needs,
      products,
      usr,
      register,
      budget,
      cpv,
      mu,
      needsCartList,
      Delete_Institution,
      Delete_Department,
      procedureType,
      ReferenceReject,
      NeedRejectData,
      viewReferenceVersion,
      userStat,
    };
  },
  methods: {
    needsManager(e) {
      if (e === "reload") {
        this.needs++;
      }
    },
    needsCartListManager(e) {
      if (e === "destroy") {
        this.NeedsCartList++;
      }
    },
    Delete_Institution(id) {
      if (id) {
        this.Delete_Institution_Id = parseInt(id.id);
      }
    },
    Delete_Department(id) {
      if (id) {
        this.Delete_Department_Id = parseInt(id.id);
      }
    },
    productsManager(e) {
      if (e === "reload") {
        this.products++;
      }
    },
    userList() {
      this.usr++;
    },
    budgetManager(e) {
      if (e === "reload") {
        this.budget++;
      }
    },
    cpvManager(e) {
      if (e === "reload") {
        this.cpv++;
      }
    },
    muManager(e) {
      if (e === "reload") {
        this.mu++;
      }
    },
    procedureTypeManager(e) {
      if (e === "reload") {
        this.procedureType++;
      }
    },
    deleteReference(data) {
      if (data) {
        this.referenceReject = { ...data };
      }
    },
    deleteNeed(data) {
      if (data) {
        this.needReject = { ...data };
      }
    },
    ViewReferenceVersion(id) {
      if (id) {
        this.viewReferenceVersion = id;
      }
    },
    userStatus(o) {
      if (o) {
        this.userStat = o;
      }
    },
    Register() {
      this.register++;
    },
  },
  watch: {
    '$route': {
      handler(val) {
        const inputs = document.getElementsByTagName('input')
        for (const input of inputs) {
          input.addEventListener('paste', (e) => {
            var bufferText = (
              (e.originalEvent || e).clipboardData || window.clipboardData
            ).getData("Text");

            e.preventDefault();

            document.execCommand("insertText", false, bufferText);
          })
        }
      }
    }
  },
  created() {
    window.$logout = this.logout
    window.$sesionExpired = () => this.$toastr.w("Sesiunea a expirat.");
    window.$roleInvalid = () => this.$toastr.w("Rolul nu este valid.");
    window.$toastr = this.$toastr
  },
  mounted() {

    if (!window.$P) {
      const root = document.getElementById("app");
      if (root) {
        root.innerHTML = "<h1><b>permisions.json</b> was not found<h1>";
      }
    }
    let roles = window.$jwt.decode(window.$jwt.getToken());
    if (roles) {
      roles = roles.userRoles;
      if (!parseInt(localStorage.getItem("currentUserRole")) && roles?.length) {
        localStorage.setItem("currentUserRole", roles.find(e => e.userRoleStatus == 'active')?.id);
      }
    }

    const topRightToastr = document.querySelector('.toast-top-right')
    if(topRightToastr) {
      topRightToastr.onclick = e => e.stopPropagation()
    }
  },
};
</script>

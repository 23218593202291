<template>
  <v-select
    :class="classes"
    ref="load"
    v-model="model"
    :options="paginated"
    label="label"
    :filterable="filterable"
    :overflowPrevent="overflowPrevent"
    :appendToBody="appendToBody"
    :loading="loading"
    :multiple="multiple"
    :disabled="disabled"
    @open="onOpen"
    @close="onClose"
    @search="(query) => (search = query)"
    @option:selected="(e) => $emit('option:selected', e)"
    @option:clear="$emit('option:clear')"
  >
    <template #option="item">
      <span class="v-select_li_custom">
        <span>{{ getName(item) }}</span>
        <i v-tooltip="item[label]" class="fas fa-info-circle"></i>
      </span>
    </template>
    <template #selected-option="item">
      <span>{{ getName(item) }}</span>
    </template>

    <template #no-options>
      <span v-show="(!hasNextPage || !paginated.length) && !searchLoading">
        Nu s-au gasit rezultate.
      </span>
    </template>
    <template #list-footer>
      <li ref="load" style="text-align: center;font-weight: 500;">
        <span v-show="searchLoading" style="display: block;">
          Se încarcă... <i class="fas fa-sync fa-spin"></i>
        </span>
      </li>
    </template>
  </v-select>
</template>

<script>
const LOAD_LIMIT = 25;

export default {
  name: "InfiniteScroll",
  props: {
    reloadCount: {
      type: Number,
      default: 0,
    },
    apiOptions: {
      type: Object,
      default: () => ({}),
    },
    value: {
      default: null,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    overflowPrevent: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    apiModule: {
      type: [Function, Boolean],
      required: true,
      default: false,
    },
    label: {
      type: String,
      default: "label",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    totalOptions: {
      type: Array,
      default: () => [],
    },
    totalRecordsCount: {
      type: Number,
      default: 0,
    },
    startDelay: {
      type: Number,
      default: 0,
    },
    startup: {
      type: Boolean,
      default: true,
    },
    excludeClones: {
      type: Array,
      default: () => [],
    },

    classes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      observer: null,
      limit: LOAD_LIMIT,
      search: "",
      options: this.totalOptions,
      loading: false,
      searchLoading: false,
      totalRecords: this.totalRecordsCount,
      model: !this.value
        ? null
        : { ...this.value, label: this.getName(this.value) },
      safeSearch: (x) => x,
      safeScroll: (x) => x,
    };
  },
  computed: {
    totalLimit() {
      return Math.max(this.totalRecords, this.options.length);
    },
    filtered() {
      return this.options;
      return this.fuseFilter(this.options, this.search, this.label);
    },
    paginated() {
      return this.filtered.slice(0, this.limit);
    },
    hasNextPage() {
      return this.paginated.length < this.totalRecords;
    },
    isValid() {
      return typeof this.apiModule == "function";
    },
    displayedPages() {
      return Math.max(1, Math.ceil(this.paginated.length / LOAD_LIMIT));
    },
    loadedPages() {
      return Math.floor(this.options.length / LOAD_LIMIT);
    },
  },
  watch: {
    model(val) {
      this.$emit("input", val);
    },
    search(val) {
      val = val?.trim().toLowerCase() || "";
      this.safeSearch(() => {
        this.limit = LOAD_LIMIT;
        this.loadData(true).then(() => {
          this.onOpen();
          this.onClose();
        });
      });
    },

    reloadCount(val) {
      this.loadData(true);
    },

    totalRecords(e) {
      this.$emit("totalRecordsCount", e);
    },
    totalRecordsCount(e) {
      if (e !== this.totalRecords) {
        this.totalRecords = e;
      }
    },
    value(val) {
      this.model = val;
    },
  },
  methods: {
    /*  inputSearch: _.debounce(async function(_search, loading) {
      if (_search.length) {
        this.options = [];
        this.search = _search;
        this.loadData(true).then(() => {
          this.onOpen();
          this.onClose();
        });
      }
    }, 500), */
    getName(item) {
      if (!item) return null;
      let name = "";

      this.label.split(",").forEach((el) => {
        name += item[el] + " - ";
      });

      name = name.substring(0, name.length - 3);

      return name;
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.totalRecords = this.totalLimit + 1;
      this.observer.disconnect();
      this.limit = LOAD_LIMIT;
    },

    infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        this.safeScroll(async () => {
          const x = await this.loadData();
          const ul = target.offsetParent;
          const scrollTop = ul?.scrollTop;

          if (typeof x == "function") {
            x();
          }
          this.limit += LOAD_LIMIT;
          await this.$nextTick();
          ul.scrollTop = scrollTop;
        });
      }
    },
    async loadData(forced) {
      const searchOn = !!this.search.trim();
      if (
        !this.isValid ||
        (forced && (isNaN(this.displayedPages) || isNaN(this.loadedPages)))
      )
        return;

      const sendApi = (page, limit = LOAD_LIMIT) => {
        this.searchLoading = true;

        const promise = this.apiModule(
          page,
          limit,
          ...(searchOn
            ? this.apiOptions.prepareSearch
              ? this.apiOptions.prepareSearch(this.search)
              : [`${this.label}=${this.search}`]
            : !searchOn &&
              this.apiOptions.prepareSearch &&
              this.apiOptions.prepareSearch("")[0]
            ? [this.apiOptions.prepareSearch(this.search)[0]]
            : [])
        );
        if (!this.isPromise(promise)) return;

        return new Promise((r) => {
          const errApi = () => {
            this.loading = false;
            this.searchLoading = false;
            r();
          };

          if (forced) this.loading = true;

          promise
            .then((res) => {
              this.loading = false;
              this.searchLoading = false;

              if (!this.isObject(res?.data)) {
                errApi();
                r();
                return;
              }
              r(() => {
                if (Number.isInteger(res.data.recordsQuantity)) {
                  this.totalRecords = res.data.recordsQuantity;
                }

                if (Array.isArray(res.data.result)) {
                  this.options.push(
                    ...res.data.result.filter(
                      (e) =>
                        ![
                          ...this.options.map((e) => e.id),
                          ...this.excludeClones,
                        ].find((id) => id === e.id)
                    )
                  );

                  this.options = this.options.map((el) => {
                    return { ...el, label: this.getName(el) };
                  });
                } else {
                  errApi();
                }
              });
            })
            .catch(errApi);
        });
      };

      if (forced) {
        this.options = [];

        return new Promise(async (r) => {
          const x = await sendApi(1);

          if (typeof x == "function") {
            x();
          }

          r(x);
        });
      } else if (1 || this.displayedPages >= this.loadedPages) {
        return new Promise(async (r) => r(await sendApi(this.loadedPages + 1)));
      }
    },
  },

  created() {
    this.safeSearch = window["apiTimeout"]();
    this.safeScroll = window["apiTimeout"](100, false, true, true);

    if (!this.options.length) {
      if (this.startup) setTimeout(this.loadData, this.startDelay ?? 0, true);
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
};
</script>

<style lang="scss"></style>

<template>
  <div class="main">
    <img src="@/assets/media/conectx-bg-landscape.jpg" alt="">
    <div class="into" style="z-index: 2;">
      <div :class="parentClass">
        <div class="row">
            <div :class="introParentClass">
                <div id="logo-container">
                    <router-link :to="'/'" class="site_logo">
                      <img src="../assets/media/logo.svg" alt="logo">
                    </router-link>
                </div>
                <router-view />
            </div>
        </div>
      </div>
      <footer class="footer-wrapper">
        <p>
          <router-link to="/">Ecosistem digital</router-link>
        </p>
        <p>
          Toate drepturile rezervate © 2021
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
  export default {
    $Title() {
      return `ConectX`;
    },
    name: 'auth',
    data: () => ({
      parentClass: 'container index',
      introParentClass: 'content-wrapper',
      tre: false
    }),
    methods: {
      adapting(current) {
        if (current === 'login') {
          this.parentClass = 'container index'
          this.introParentClass = 'content-wrapper'
        }
        if (current === 'signin1' || current === 'signin2') {
          this.parentClass = 'container register-container register-taxpayer'
          this.introParentClass = 'content-wrapper'
        }
        if (current === 'forgot') {
          this.parentClass = 'container'
          this.introParentClass = 'content-wrapper'
        }
      }
    },
    watch: {
      $route(current) {
        this.adapting(current.name)
      }
    },
    created() {
      // document.title = `${document.title} - Autorizare`

    },
    mounted() {
      this.adapting(this.$route.name)

      document.querySelector('.main > img').addEventListener('load', (e) => {
        e.target.style.opacity = 1
      })
    }
  }
</script>
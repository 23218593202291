const initPermision = (rule) => {
  const cRole = window.$getRoleAll()
  const pList = window.$P

  if(!cRole || !pList) {
    return false
  }

  const role = String(cRole.role).trim().toLowerCase()

  if(Array.isArray(pList[rule]) && pList[rule].find(e => e == role)) {
    return true
  }
  return false  
}
export default initPermision;
<template>
  <div :id="Id" class="WysiwygParent" style="background-color: #fff">
    <textarea ref="textarea"></textarea>
  </div>
</template>

<script>
import $ from "jquery";
import "@/modules/summernote/jquery-3.4.1.slim.min.js";
import "@/modules/summernote/summernote-lite.min.js";
import "@/modules/summernote/summernote-ro-RO.js";
import "@/modules/summernote/summernote-lite.min.css";

export default {
  props: {
    value: {
      default: "",
    },
    fullMode: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    maxHeight: {
      type: [String, Number],
      required: false,
      default: 0,
    },
  },
  watch: {
    value(x) {
      if (!x || !this.textFromHtml(x)) {
        $(this.getEl()).summernote("code", x);
      }
    },
  },
  data() {
    return {
      Id: `ID${this.makeid(6)}`,
      observer: null,
    };
  },
  methods: {
    updateValue(x) {
      x = x?.target?.value?.trim() || "";
      if (x || this.value) {
        this.$emit("input", x);
      }
    },
    disconnectObserver() {
      this.observer?.disconnect();
      this.observer = null;
    },
    disabledFlexGrow(init) {
      if (!init) return;
      const editor = init.editor[0];
      const editable = init.editable[0];
      const editingArea = init.editingArea[0];

      this.observer = new MutationObserver((mutations) => {
        mutations.forEach(() => {
          editor.style.flexGrow = "unset";
          editingArea.style.flexGrow = "unset";
          this.disconnectObserver();
        });
      });

      this.observer.observe(editable, {
        attributes: true,
        attributeFilter: ["style"],
      });
    },
    getEl() {
      return this.$refs.textarea;
    },
  },
  beforeDestroy() {
    this.disconnectObserver();
  },
  mounted() {
    try {
      $(this.getEl()).val(this.value);

      window.requestAnimationFrame(() => {
        $(this.getEl()).summernote(
          Object.assign(
            {
              lang: "ro-RO",
              callbacks: {
                onInit: this.disabledFlexGrow,

                onPaste: function(e) {
                  var bufferText = (
                    (e.originalEvent || e).clipboardData || window.clipboardData
                  ).getData("Text");
                  
                  e.preventDefault();

                  document.execCommand("insertText", false, bufferText);
                },
              },
            },

            this.minHeight ? { minHeight: parseInt(this.minHeight) } : {},
            this.maxHeight ? { maxHeight: parseInt(this.maxHeight) } : {},
            this.fullMode
              ? {
                  toolbar: [
                    [
                      "FontStyle",
                      [
                        "fontname",
                        "fontsize",
                        "fontsizeunit",
                        "color",
                        "forecolor",
                        "backcolor",
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                        "superscript",
                        "subscript",
                        "clear",
                      ],
                    ],
                    ["Para", ["style", "ol", "ul", "paragraph", "height"]],
                    ["Insert", ["picture", "link", "video", "table", "hr"]],
                    [
                      "Misc",
                      ["fullscreen", "codeview", "undo", "redo", "help"],
                    ],
                  ],
                }
              : {
                  toolbar: [
                    ["style", ["style"]],
                    ["font", ["bold", "underline", "clear"]],
                    ["color", ["color"]],
                    ["para", ["ul", "ol", "paragraph"]],
                    ["table", ["table"]],
                    ["insert", ["link", "picture", "video"]],
                    ["view", ["fullscreen", "help"]],
                  ],
                }
          )
        );
        $(`#${this.Id}`).on("summernote.change", this.updateValue);
      });
    } catch (e) {}
  },
};
</script>
